import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		nome: '',
		valor: 86400000,
		tipo: '',
		escala: 'DIA',
		alarmes: [],
		contadores : []
	},
};

const frequenciaReducer = createSlice({
	name: 'frequenciaReducer',
	initialState,
	reducers: {
		setValorFrequencia(state, action) {
			state.form.valor = +action.payload;
		}, //Define a frequencia em numeros qual o alarme sera "chamado"
		setTipoFrequencia(state, action) {
			state.form.tipo = action.payload;
		},
		setEscala(state, action) {
			state.form.escala = action.payload;
		},
		setNomeFrequencia(state, action) {
			state.form.nome = action.payload;
		},
		setAlarmesFrequencia(state, action) {
			state.form.alarmes = action.payload;
		},
		setContadores(state, action) {
			state.form.contadores = action.payload;
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		populateToEditFrequencia(state, action) {
			state.form = action.payload;
		},
		setUpdateAlarme(state, action) {
			let { index } = action.payload
			state.form.alarmes[index] = action.payload;
		},

		clear(state, action) {
			state.form = initialState.form;
		},
	},
});

export const {
	setValorFrequencia,
	setTipoFrequencia,
	setEscala,
	setNomeFrequencia,
	setAlarmesFrequencia,
	populateToEdit,
	populateToEditFrequencia,
	clear : clearFrequenciaForm,
	setUpdateAlarme,
	setContadores,
} = frequenciaReducer.actions;

export default frequenciaReducer.reducer;
