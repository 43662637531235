/* export const addToList = (lista, toAdds, filterKey, insertPos, filterValue, delCount) => {
    let listaCopy = [...lista]
    let filteredLista = new Array()
    toAdds.forEach(toadd => filteredLista = [...filteredLista, ...listaCopy.filter(li => li[filterKey] != toadd[filterKey])])
    if ((toAdds != null) && (Array.isArray(toAdds))) {
        if (insertPos || insertPos == 0) {
            filteredLista.splice(insertPos, delCount, ...toAdds)
        } else {
            let index = listaCopy.findIndex(obj => obj[filterKey] == filterValue)
            filteredLista.splice(index, delCount, ...toAdds)
        }
    } else {
        throw "Itens a serem adicionados precisa ser um array não vazio"
    }

    return filteredLista

}
 */

export const addToList = (lista, toAdds, equalKey, insertPos, delCount) => {
    let listaCopy = lista ? [...lista] : new Array()
    if (toAdds != null) {
        if (!Array.isArray(toAdds)) toAdds = [toAdds]
     


        let filterArray = listaCopy.map(li => li[equalKey])
        let toAddFilterArray = toAdds.map(to => to[equalKey])
        toAddFilterArray.forEach(toAd => {
            if(!filterArray.includes(toAd)){
                let objFinded = toAdds.find(liItem => liItem[equalKey] == toAd)
                if (insertPos || insertPos == 0) {
                    listaCopy.splice(insertPos, delCount || 0, objFinded)
                } else {
                    listaCopy.push(objFinded)
                }
            }
        }  )

       
    } else {
        throw "Itens a serem adicionados precisa ser um array não vazio"
    }

    return listaCopy

}

export const removeAtList = (lista, removeKey, removeIndex, removeValueRef) => {
    let listaCopy = [...lista]
    let filteredLista
    if (removeIndex || removeIndex == 0) {
        filteredLista = listaCopy.filter((li, index) => index != removeIndex)
    } else {
        filteredLista = listaCopy.filter(li => li[removeKey] != removeValueRef)
    }


    return filteredLista

}

export function move(array, from, to) {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
}
