import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        unidadeId:"",
        registros: []
    },

}


const contadorReducer = createSlice({
    name: 'contadorReducer',
    initialState,
    reducers: {
        setNomeContador(state, action) {
            state.form.nome = action.payload
        },
        setRegistroContador(state, action) {
            state.form.registros = action.payload
        },
        setUnidadeContador(state, action) {
            state.form.unidadeId = action.payload
        },
        clear(state, action) {
			state.form = initialState.form;
		},
        

    },
})

export const { setNomeContador, setRegistroContador, setUnidadeContador, clear : clearContadorForm } = contadorReducer.actions
export default contadorReducer.reducer