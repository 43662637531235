import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		descricaoDoProblema: "",
		dataDeAbertura: "",
		status: "",
		recurso: null,
		isPopulated: false,
		ordemDeManutencao: null,
		ordemServModos: [],
		tarefas: [],
		statusId: "",
		userId: "",
		osMantenedor: null,
		recursos: [],
		osTipos: [],
	},
	ordemDeManutencaoId: "",
	ordemDeManutencaoView: false,
	ordensDeServico: [],
	ordemDeServicoView: null,
	tarefaParaApontar: null,
}

const ordemDeServicoReducer = createSlice({
	name: "ordemDeServicoReducer",
	initialState,
	reducers: {
		setId(state, action) {
			state.form.id = action.payload
		},
		setRecursos(state, action) {
			state.form.recursos = action.payload
		},
		setEstoqueSaida(state, action) {
			state.form.estoqueSaida = action.payload
		},
		setEstoqueDestino(state, action) {
			state.form.estoqueDestino = action.payload
		},
		setClearEstoquesOS(state, action) {
			state.form.estoqueDestinoId = ""
			state.form.estoqueSaidaId = ""
		},
		setSolicitante(state, action) {
			state.form.userId = action.payload
		},
		setTarefas(state, action) {
			state.form.tarefas = action.payload
		},
		setModosDeFalha(state, action) {
			state.form.ordemServModos = action.payload
		},
		setOsTipos(state, action) {
			state.form.osTipos = action.payload
		},
		setAddTipoOs(state, action) {
			state.form.osTipos = [...state.form.osTipos, action.payload]
		},
		setUpdateTipoOs(state, action) {
			let { index } = action.payload
			state.form.osTipos[index] = action.payload
		},
		setOsMantenedor(state, action) {
			/* state.form.osMantenedor = [...state.form.osMantenedor, action.payload] */
			state.form.osMantenedor = action.payload
		},
		setOrdemDeServicoView(state, action) {
			state.ordemDeServicoView = action.payload
		},
		setOrdemDeManutencaoId(state, action) {
			state.ordemDeManutencaoId = action.payload
		},
		setMotivo(state, action) {
			state.form.descricaoDoProblema = action.payload
		},
		updateSingleOsRow(state, action) {
			let index = state.ordensDeServico.findIndex(
				(os) => os.id == action.payload.id
			)
			state.ordensDeServico[index] = action.payload
		},
		setUpdateMantenedores(state, action) {
			let { mantenedoresFromOs, osId } = action.payload
			let index = state.ordensDeServico.findIndex((os) => os.id == osId)
			state.ordensDeServico[index].osMantenedor = mantenedoresFromOs
		},
		setObservacao(state, action) {
			state.form.observacao = action.payload
		},
		setDataAbertura(state, action) {
			state.form.dataDeAbertura = action.payload
		},
		setStatus(state, action) {
			state.form.status = action.payload
		},
		setAnaliseId(state, action) {
			state.form.analiseId = action.payload
		},
		setRecurso(state, action) {
			state.form.recurso = action.payload
		},
		setOrdens(state, action) {
			state.ordensDeServico = action.payload
		},
		setOrdemDeManutencaoView(state, action) {
			state.ordemDeManutencaoView = action.payload
		},
		setIsPopulatedOs(state, action) {
			state.form.isPopulated = action.payload
		},
		populateToEditOrdem(state, action) {
			state.form = action.payload
			
		},
		setTarefaParaApontar(state, action) {
			state.tarefaParaApontar = action.payload
		},
		setOrdemDeManutencao(state, action) {
			state.form.ordemDeManutencao = action.payload
		},
		addTarefas(state, action) {
			if (!state.form.tarefas) {
				state.form.tarefas = [action.payload]
			} else {
				state.form.tarefas = [...state.form.tarefas, action.payload]
			}
		},
		clear(state) {
			state = initialState
		},
	},
})

export const {
	setId,
	setMotivo,
	setOrdemDeManutencao,
	setIsPopulatedOs,
	setOrdemDeManutencaoView,
	setTarefaParaApontar,
	setObservacao,
	setAnaliseId,
	setOrdemDeServicoView,
	setDataAbertura,
	setStatus,
	updateSingleOsRow,
	setUpdateTipoOs,
	setRecurso,
	populateToEditOrdem,
	setOrdens,
	setOrdemDeManutencaoId,
	clear: clearOrdemDeServicoForm,
	setTarefas,
	setSolicitante,
	setModosDeFalha,
	addTarefas,
	setOsTipos,
	setOsMantenedor,
	setRecursos,
	setAddTipoOs,
	setUpdateMantenedores,
	setEstoqueDestino,
	setEstoqueSaida,
	setClearEstoquesOS,
} = ordemDeServicoReducer.actions
export default ordemDeServicoReducer.reducer
