import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   form: {
        nome: '',
        valor: 0.0,
        cor: '',
        boundRule : ''
    }
}


const limiteDeControleReducer = createSlice({
    name: 'limiteDeControleReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.form.nome = action.payload
        },
        setValor(state, action) {
            state.form.valor = action.payload
        },
        setBoundRule(state, action) {
            state.form.boundRule = action.payload
        },
        setCor(state, action) {
            state.form.cor = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
			state.form = initialState.form;
		},
        clearLimiteDeControle(state, action) {
			state.form = initialState.form;
		},

    },
})

export const { setNome, setValor, setBoundRule, setCor, populateToEdit, clear,clearLimiteDeControle } = limiteDeControleReducer.actions
export default limiteDeControleReducer.reducer