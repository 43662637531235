import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        parametro: "",
        especificacao: null,
        frequencia: null,
        parametroId: ""
    },
    renderingControle: null
}


const controleReducer = createSlice({
    name: 'controleReducer',
    initialState,
    reducers: {
        setParametro(state, action) {
            state.form.parametro = action.payload
        },
        setRenderingControle(state, action) {
            state.renderingControle = action.payload
        },
        setParametroId(state, action) {
            state.form.parametroId = action.payload
        },
        setEspecificacao(state, action) {
            state.form.especificacao = action.payload
        },
        setFrequencia(state, action) {
            state.form.frequencia = action.payload
        },
        populateToEditControle(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
            state.form = initialState.form;
        },

    },
})

export const { setParametro,
    setEspecificacao,
    setFrequencia, setParametroId, clear: clearControleForm, setRenderingControle, populateToEditControle } = controleReducer.actions
export default controleReducer.reducer