import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		
	},
};

const workGroupReducer = createSlice({
	name: 'workGroupReducer',
	initialState,
	reducers: {
		
		populateToEditWorkGroupForm(state, action) {
			state.form = action.payload;
		},
		setWorkGroupNome(state, action) {
			state.form.nome = action.payload;
		},
		setWorkMembers(state, action) {
			state.form.workMembers = action.payload;
		},
		setAddWorkMember(state, action) {
			state.form.workMembers = [...state.form.workMembers, action.payload]
		},
		setWorkGroupObjetivo(state, action) {
			state.form.objetivo = action.payload;
		},
		clear(state) {
			state.form = initialState.form;
		},
	},
});

export const {  populateToEditWorkGroupForm, clear:clearworkGroupReducer, setWorkGroupNome, setWorkGroupObjetivo, setWorkMembers,setAddWorkMember } = workGroupReducer.actions;
export default workGroupReducer.reducer;
