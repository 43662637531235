import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		quantidade: 0,
		unidade: '',
		material: '',
		unidadeId : '',
		materialId : '',
	},
};

const unidadeMaterialReducer = createSlice({
	name: 'unidadeMaterialReducer',
	initialState,
	reducers: {
		setQuantidadeUnidadeMaterial(state, action) {
			state.form.quantidade = +action.payload;
		},
		setUnidadadeUnidadeMaterial(state, action) {
			state.form.unidade = action.payload;
			state.form.unidadeId = action.payload.id
		},
		
		setMaterialUnidadematerial(state, action) {
			state.form.material = action.payload;
			state.form.materialId = action.payload.id;
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
		clearUnidadeMaterial(state ) {
			state.form = initialState.form;
		}
	},
});

export const {
	setMaterialUnidadematerial,
	setQuantidadeUnidadeMaterial,
	setUnidadadeUnidadeMaterial,
	populateToEdit,
	clear,
	clearUnidadeMaterial
} = unidadeMaterialReducer.actions;
export default unidadeMaterialReducer.reducer;
{}