import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		folhas: [],
		filtro: '',
		parametros: [],
		markedParams: {},
		descricao: [],
		alarmes: [],
		parametrosChoosed: [],
		folha: null,
		actualPage: 0,
	},
};

const folhaDeVerificacaoReducer = createSlice({
	name: 'folhaDeVerificacaoReducer',
	initialState,
	reducers: {
		setFiltro(state, action) {
			state.form.filtro = action.payload;
		},
		setParametros(state, action) {
			state.form.parametros = action.payload;
		},
		setMakrParam(state, action) {
			let params = action.payload;
			params.forEach((e) => {
				state.form.markedParams[e] = true;
			});
		},
		setUnmarkParams(state, action) {
			let params = action.payload;
			params.forEach((e) => {
				state.form.markedParams[e] = false;
			});
		},
		setDescricao(state, action) {
			state.form.descricao = action.payload;
		},
		setAlarme(state, action) {
			let acionador = action.payload;
			if (acionador.localEdit) {
				state.form.acionadores[acionador.index] = acionador;
			} else {
				if (state.form.acionadores) state.form.acionadores = [...state.form.acionadores, acionador];
				else state.form.acionadores = [acionador];
			}
		},
		setAlarmees(state, action) {
			state.form.acionadores = action.payload;
		},
		setParametrosChoosed(state, action) {
			state.form.parametrosChoosed = action.payload;
		},
		setFolhas(state, action) {
			state.form.folhas = action.payload;
		},
		setFolha(state, action) {
			state.form.folha = action.payload;
		},
		setActualPage(state, action) {
			state.form.actualPage = action.payload;
		},
		setAnaliseFromPagina(state, action) {
			let { campoIndex, valor } = action.payload;
			state.form.folha.paginas[state.form.actualPage].campos[campoIndex].analise.valor = valor;
		},
		setPlano(state, action) {
			state.form.processo = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
	},
});

export const {
	setFiltro,
	setParametros,
	setAnaliseFromPagina,
	setMakrParam,
	setFolhas,
	setActualPage,
	setFolha,
	setUnmarkParams,
	setAlarmees,
	setDescricao,
	setParametrosChoosed,
	setAlarme,
	clear: clearFolhaDeVerificacaoForm
} = folhaDeVerificacaoReducer.actions;
export default folhaDeVerificacaoReducer.reducer;
