import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    grupoForm: {
        nome: "",
    }
}

const grupoReducer = createSlice({
    name: 'grupoReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.grupoForm.nome = action.payload
        },
        populateToEdit(state, action) {
            state.grupoForm = action.payload
        },
        clear(state) {
            state.grupoForm = {}
        },

    },
})

export const { setNome,populateToEdit, clear } = grupoReducer.actions
export default grupoReducer.reducer