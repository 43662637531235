import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		nome: "",
		folhasDeVerificacao: [],
		camposDeVerificacao: [],
		folha: null,
		actualPage: 0,
		planoDeControle: "",
		processo: "",
	},
	cadernos: [],
	cadernoView: {
		caderno: {},
		paginaAtual: 1,
		data: "",
	},
}

const cadernoDeVerificacaoReducer = createSlice({
	name: "cadernoDeVerificacaoReducer",
	initialState,
	reducers: {
		setFiltro(state, action) {
			state.form.filtro = action.payload
		},
		setNome(state, action) {
			state.form.nome = action.payload
		},
		setProcesso(state, action) {
			state.form.processo = action.payload
		},
		setCadernos(state, action) {
			state.cadernos = action.payload
		},
		setUpdateCadernoRow(state, action) {
			let index = state.cadernos.findIndex(
				(c) => c.id == action.payload.id
			)
			state.cadernos[index] = action.payload
		},
		setCadernoView(state, action) {
			state.cadernoView.caderno = action.payload
		},
		setUpdateSingleFolha(state, action) {
			let index = state.cadernoView.caderno.folhasDeVerificacao.findIndex(
				(f) => f.id == action.payload.id
			)
			state.cadernoView.caderno.folhasDeVerificacao[index] =
				action.payload
		},
		setUpdateSingleRegistro(state, action) {
			let indexFolha = state.cadernoView.caderno.folhasDeVerificacao.findIndex(
				(f) => f.id == action.payload.folhaDeVerificacaoId
			)
			let index = state.cadernoView.caderno.folhasDeVerificacao[
				indexFolha
			].registrosDeCampo.findIndex((r) => r.id == action.payload.id)
			state.cadernoView.caderno.folhasDeVerificacao[
				indexFolha
			].registrosDeCampo[index] = action.payload
		},
		setData(state, action) {
			state.cadernoView.caderno.folhasDeVerificacao[
				state.cadernoView.paginaAtual - 1
			].data = action.payload
		},
		setFolhasDeVerificacao(state, action) {
			state.cadernoView.folhasDeVerificacao = action.payload
		},
		setCamposDeVerificacao(state, action) {
			state.form.camposDeVerificacao = action.payload
		},
		setFormulaCampoDeVerificacao(state, action) {
			let { index, formula } = action.payload
			state.form.camposDeVerificacao[index].formula = formula
		},
		setRegraDeCorrecao(state, action) {
			let { index, campo } = action.payload
			state.form.camposDeVerificacao[index].campo = campo
			state.form.camposDeVerificacao[index].campoId = campo.id
		},
		addCampo(state, action) {
			state.form.camposDeVerificacao.push(action.payload)
		},
		setPlano(state, action) {
			state.form.planoDeControle = action.payload
		},
		setValorRegistro(state, action) {
			state.cadernoView.caderno.folhasDeVerificacao[
				state.cadernoView.paginaAtual - 1
			].registrosDeCampo[action.payload.index].valor =
				action.payload.valor
		},
		updateByFolhaId(state, action) {
			//acha a folha de verificacao pelo id e atualiza
		},
		setJustificativa(state, action) {
			const justificativas =
				state.cadernoView.caderno.folhasDeVerificacao[
					state.cadernoView.paginaAtual - 1
				].registrosDeCampo[action.payload.indexRegistro].justificativas
			if (!justificativas) {
				state.cadernoView.caderno.folhasDeVerificacao[
					state.cadernoView.paginaAtual - 1
				].registrosDeCampo[
					action.payload.indexRegistro
				].justificativas = new Array(action.payload)
			} else {
				let justificativa = action.payload
				state.cadernoView.caderno.folhasDeVerificacao[
					state.cadernoView.paginaAtual - 1
				].registrosDeCampo[
					action.payload.indexRegistro
				].justificativas = [...justificativas, justificativa]
			}
		},
		populateJustificativasRegistroDeCampo(state, action) {
			state.cadernoView.caderno.folhasDeVerificacao[
				state.cadernoView.paginaAtual - 1
			].registrosDeCampo[action.payload.indexRegistro].justificativas =
				action.payload.justificativas
		},
		removeJustificativaFromRegistro(state, action) {
			state.cadernoView.caderno.folhasDeVerificacao[
				state.cadernoView.paginaAtual - 1
			].registrosDeCampo[
				action.payload.indexRegistro
			].justificativas = state.cadernoView.caderno.folhasDeVerificacao[
				state.cadernoView.paginaAtual - 1
			].registrosDeCampo[
				action.payload.indexRegistro
			].justificativas.filter(
				(j, i) => i != action.payload.indexJustificativa
			)
		},
		setActualPage(state, action) {
			state.cadernoView.paginaAtual = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setFiltro,
	populateToEdit,
	setUpdateCadernoRow,
	clear: clearCadernoDeVerificacaoForm,
	setData,
	setCadernoView,
	populateJustificativasRegistroDeCampo,
	addCampo,
	removeJustificativaFromRegistro,
	setValorRegistro,
	setActualPage,
	setJustificativa,
	setNome,
	setCamposDeVerificacao,
	setCadernos,
	setPlano,
	setFolhasDeVerificacao,
	setProcesso,
	setUpdateSingleRegistro,
	setUpdateSingleFolha,
	setFormulaCampoDeVerificacao,
	updateByFolhaId,
	setRegraDeCorrecao,
} = cadernoDeVerificacaoReducer.actions
export default cadernoDeVerificacaoReducer.reducer
