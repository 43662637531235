import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		estoqueId: null,
		userId: null,
		unidadesMateriais: [],
	},
	
	requisicoesMaterial: [],
}

const requisicaoMaterialReducer = createSlice({
	name: 'requisicaoMaterialReducer',
	initialState,
	reducers: {
		setEstoqueIdRequisicao(state, action) {
			state.form.estoqueId = action.payload
		},
		setRequisicaoUserId(state, action) {
			state.form.userId = action.payload
		},
		setUnidadeMaterialRequisicao(state, action) {
			state.form.unidadesMateriais = [...state.form.unidadesMateriais, action.payload]
		},
		setUpdateUnidadeMaterialRequisicao(state, action) {
			let {index} = action.payload
			state.form.unidadesMateriais[index] = action.payload
		},
		setUnidadesMateriaisRequisicao(state, action) {
			state.form.unidadesMateriais = action .payload
		},
		setAddItemRequisicao(state, action) {
			state.form.unidadesMateriais = [...state.form.unidadesMateriais, action.payload]
		},
		setItensDeRequisicao(state, action) {
			state.form.unidadesMateriais = action.payload
		},
		setRequisicoesMaterial(state, action) {
			state.requisicoesMaterial =  action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state) {
			state.form = initialState.form
		},
		clearRequisicoesMaterial(state) {
			state.requisicoesMaterial = initialState.requisicoesMaterial
		},
	},
})

export const {
	populateToEdit: populateToEditRequisicaoDeMaterial,
	clear: clearRequisicaoDeMaterial,
	setAddItemRequisicao,
	setEstoqueIdRequisicao,
	setItensDeRequisicao,
	setRequisicaoUserId,
	setRequisicoesMaterial,
	clearRequisicoesMaterial,
	setUnidadeMaterialRequisicao,
	setUnidadesMateriaisRequisicao,
	setUpdateUnidadeMaterialRequisicao
} = requisicaoMaterialReducer.actions
export default requisicaoMaterialReducer.reducer
