import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		nome: '',
		dataInicio: '',
		ativo: true,
		dias: [1,2,3,4,5],
		dataUltimoDisparo: new Date().toISOString(),
		isHabilitado: true,
		isPopulated: false,
		isAdd: false,
		isEdit: false,
		isEmbedded: false,
		actionType: '',
		contadores: [],
	},
};

const alarmeReducer = createSlice({
	name: 'alarmeReducer',
	initialState,
	reducers: {
		setNome(state, action) {
			state.form.nome = action.payload;
		},
		setAtivo(state, action) {
			state.form.ativo = action.payload;
		},

		setIsHabilitado(state, action) {
			state.form.isHabilitado = action.payload;
		},

		setDataIntervalo(state, action) {
			state.form.dataIntervalo = action.payload;
		},
		setDias(state, action) {
			state.form.dias = action.payload;
		},
		setContadores(state, action) {
			state.form.contadores = action.payload;
		},
		setIsAdd(state, action) {
			state.form.isAdd = action.payload;
		},
		setIsEdit(state, action) {
			state.form.isEdit = action.payload;
		},
		setActionType(state, action) {
			state.form.actionType = action.payload;
		},
		populateToEditAlarme(state, action) {
			state.form = action.payload;
		},
		setDataInicio(state, action) {
			state.form.dataInicio = action.payload;
		},
		setDataFim(state, action) {
			state.form.dataFim = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
	},
});

export const {
	// setIntervalo,
	// setType,
	setContadores,
	setDias,
	setDataIntervalo,
	setNome,
	// setScale,
	setIsHabilitado,
	populateToEditAlarme,
	clear: clearAlarmeForm,
	setIsAdd,
	setIsEdit,
	setAtivo,
	setActionType,
	setDataInicio,
	setDataFim,
} = alarmeReducer.actions;
export default alarmeReducer.reducer;
