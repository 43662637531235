import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    etapas: [],
    parametros: [],
    composicoes: [],
    tarefas: [],
    acoes: [],
    recursos: [],
    dimensoes: [],
    classes: [],
    componentes: [],
    classesComponente: [],
    trocas: [],
    manutencoes: [],
    grupos: [],
    contadores: [],
    faixas: [],
    correcoes: [],

}

const pickersReducer = createSlice({
    name: 'pickersReducer',
    initialState,
    reducers: {
        setEtapas(state, action) {
            state.etapas = action.payload
        },
        setParametros(state, action) {
            state.parametros = action.payload
        },
        setComposicoes(state, action) {
            state.composicoes = action.payload
        },
        setTarefas(state, action) {
            state.tarefas = action.payload
        },
        setCorrecoes(state, action) {
            state.correcoes = action.payload
        },
        setAcoes(state, action) {
            state.acoes = action.payload
        },
        setRecursos(state, action) {
            if (!action.payload) {
                state.recursos = new Array()
            } else {
                let { checked, recurso } = action.payload

                if (!state.recursos) state.recursos = new Array()
                if (checked) {
                    state.recursos = [...state.recursos, recurso]
                } else {
                    state.recursos = state.recursos.filter(rec => rec.id != recurso.id)
                }
            }



        },
        setDimensoes(state, action) {
            state.dimensoes = action.payload
        },
        setFaixas(state, action) {
            state.faixas = action.payload
        },
        setClasses(state, action) {
            state.classes = action.payload
        },
        setClassesComponente(state, action) {
            state.classesComponente = action.payload
        },
        setComponentes(state, action) {
            state.componentes = action.payload
        },
        setTrocas(state, action) {
            state.trocas = action.payload
        },
        setManutencoes(state, action) {
            state.manutencoes = action.payload
        },
        setGrupos(state, action) {
            state.grupos = action.payload
        },
        setContadores(state, action) {
            state.contadores = action.payload
        },
        clear(state, action) {
            state.etapas = []
            state.parametros = []
            state.composicoes = []
            state.tarefas = []
            state.recursos = []
            state.acoes = []
            state.componentes = []
            state.classes = []
            state.classesComponente = []
            state.trocas = []
            state.grupos = []
            state.contadores = []
        },
        clearComposicoes(state, action) {
            state.composicoes = []

        },
        clearManutencao(state, action) {
            state.acoes = []
            state.trocas = []

        },
        clearContadores(state, action) {
            state.contadores = []

        },

    },
})

export const { setEtapas, setParametros, setCorrecoes, setFaixas, setContadores, setManutencoes, clearManutencao, setComponentes, setGrupos, setClassesComponente, setComposicoes, setTarefas, setRecursos, setAcoes, setDimensoes, setClasses, setTrocas, clear, clearComposicoes, clearContadores } = pickersReducer.actions
export default pickersReducer.reducer