import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		apelido: '',
	},
};

const teamMemberReducer = createSlice({
	name: 'teamMemberReducer',
	initialState,
	reducers: {
		setApelido(state, action) {
			state.form.apelido = action.payload;
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		clear(state) {
			state.form = initialState.form;
		},
	},
});

export const { setApelido, populateToEdit: populateToEditTeamMember, clear:clearTeamMemberReducer } = teamMemberReducer.actions;
export default teamMemberReducer.reducer;
