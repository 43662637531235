


export let enviromentnode = process.env.REACT_APP_DEV === "active"

export const endPointProcesso = enviromentnode ? process.env.REACT_APP_END_POINT_PROCESSO_DEV : process.env.REACT_APP_END_POINT_PROCESSO_PROD
export const endPointManutencao = enviromentnode ? process.env.REACT_APP_END_POINT_MANUTENCAO_DEV : process.env.REACT_APP_END_POINT_MANUTENCAO_PROD
export const endPointCommons = enviromentnode ? process.env.REACT_APP_END_POINT_COMMONS_DEV : process.env.REACT_APP_END_POINT_COMMONS_PROD
export const endPointSuprimentos = enviromentnode ? process.env.REACT_APP_END_POINT_SUPRIMENTOS_DEV : process.env.REACT_APP_END_POINT_SUPRIMENTOS_PROD
export const endPointTimer = /* enviromentnode ? process.env.REACT_APP_END_POINT_TIMER : */ process.env.REACT_APP_END_POINT_TIMER_PROD
export const endPointUser = /* enviromentnode ? process.env.REACT_APP_END_POINT_USER : */ process.env.REACT_APP_END_POINT_USER_PROD
export const endPointRabbit = /* enviromentnode ? process.env.REACT_APP_END_POINT_RABBIT : */ process.env.REACT_APP_END_POINT_RABBIT_PROD
export const endPointMathema = /* enviromentnode ? process.env.REACT_APP_END_POINT_MATHEMA : */ process.env.REACT_APP_END_POINT_MATHEMA_PROD
export const endPointCorrecao = /* enviromentnode ? process.env.REACT_APP_END_POINT_CORRECAO : */ process.env.REACT_APP_END_POINT_CORRECAO_PROD
export const endPointPlanoDeControle = enviromentnode ? process.env.REACT_APP_END_POINT_PLANODECONTROLE_DEV : process.env.REACT_APP_END_POINT_PLANODECONTROLE_PROD

export const ids = {
   novoRecursoButton: 'novoRecursoButton',
   novaSolicitacaoButton: "novaSolicitacaoButton",
   novaOrdemButton: "novaOrdemButton",
   checkActiveRecurso: "checkActiveRecurso",
   editRecursoButton: "editRecursoButton",
   solicitacoesDeServicoAcaoColumn: 'solicitacoesDeServicoAcaoColumn',
   ordensDeServicoIndicadorButton: 'ordensDeServicoIndicadorButton',
   ordensDeServicoTarefasColumn: 'ordensDeServicoTarefasColumn',
   ordensDeServicoAcaoColumn: 'ordensDeServicoAcaoColumn',
   ordensDeServicoTempoColumn: 'ordensDeServicoTempoColumn',
}

export const beforeSaveVerifyHandler = (form, addToastCallback) => {

   if (form.recursos.length === 0) {
      addToastCallback("Selecione pelo menos um recurso", {
         appearance: "warning",
         autoDismiss: true,
      })
   } else if (form.descricaoDoProblema.length === 0) {
      addToastCallback("Preencha a descrição do problema", {
         appearance: "warning",
         autoDismiss: true,
      })
   } else return true
}

//export const endPointProcesso =  "http://localhost:4000/"
//export const endPointSuprimentos = "http://localhost:4014/"
//export const endPointManutencao = "http://localhost:4001/"
//export const endPointCommons = "http://localhost:4012/"
//export const endPointTimer = "http://localhost:4030/"
//export const endPointUser = "http://localhost:4008/"
//export const endPointRabbit = "http://localhost:3005/"
//export const endPointCorrecao = "http://localhost:3009/"
//export const endPointMathema = "http://localhost:8001/"
//export const endPointPlanoDeControle = "http://localhost:3044/"



export const paths = {
   home: "/Home",
   qrReader: "/qrReader",
   times: "/times",
   timeForm: "/timeForm",
   kardex: "/kardex",
   processoForm: "/processoForm",
   regraDeCorrecaoForm: "/regraDeCorrecaoForm",
   acessoNaoPermitido: "/acessoNaoPermitido",
   fluxoDeProcessoForm: "/fluxoDeProcessoForm",
   operacaoForm: "/operacaoForm",
   planoDeControleForm: "/planoDeControleForm",
   linhaDePlanoDeControleForm: "/linhaDePlanoDeControleForm",
   parametroForm: "/parametroForm",
   fluxosDeProcesso: "/fluxosDeProcesso",
   unidadeForm: "/unidadeForm",
   limiteDeControleForm: "/limiteDeControleForm",
   especificacaoForm: "/especificacaoForm",
   arvoreEstruturalForm: "/arvoreEstruturalForm",
   branchLevelForm: "/branchLevelForm",
   recursoForm: "/recursoForm",
   componenteForm: "/componenteForm",
   unidadeMaterialForm: "/unidadeMaterialForm",
   materialForm: "/materialForm",
   materialCompostoForm: "/materialCompostoForm",
   alarmeForm: "/alarmeForm",
   controleForm: "/controleForm",
   solicitacaoDeServicoForm: "/solicitacaoDeServicoForm",
   recursoPicker: "/recursoPicker",
   solicitacoesDeServico: "/solicitacoesDeServico",
   modoDeFalhaForm: "/modoDeFalhaForm",
   tarefaForm: "/tarefaForm",
   acaoForm: "/acaoForm",
   acaoPicker: "/acaoPicker",
   cadernoDeVerificacaoForm: "/cadernoDeVerificacaoForm",
   cadernoDeVerificacao: "/cadernoDeVerificacao",
   folhaDeVerificacaoForm: "/folhaDeVerificacaoForm",
   campoDeVerificacao: "/campoDeVerificacao",
   cadernos: "/cadernos",
   formularioForm: "/formularioForm",
   planosDeControle: "/planosDeControle",
   classeDeRecursoForm: "/classeDeRecursoForm",
   classeDeRecursoPicker: "/classeDeRecursoPicker",
   classeDeComponenteForm: "/classeDeComponenteForm",
   dimensaoForm: "/dimensaoForm",
   aprovaStatus: "/aprovacaoStatus",
   contadorForm: "/contadorForm",
   frequenciaForm: "/frequenciaForm",
   requisicaoMaterialForm: "/requisicaoMaterialForm",
   requisicoesDeMaterial: "/requisicoesDeMaterial",
   registroForm: "/registroForm",
   turnoForm: "/turnoForm",
   setorForm: "/setorForm",
   ordemDeServicoForm: '/ordemDeServicoForm',
   ordensDeServico: '/ordensDeServico',
   ordemDeManutencaoForm: '/ordemDeManutencaoForm',
   ordensDeManutencao: '/ordensDeManutencao',
   registroDeTarefaForm: '/registroDeTarefaForm',
   loginForm: '/Login',
   firstLoginForm: '/FirstLogin',
   itemDeRequisicao: "/itemDeRequisicao",
   planosDeManutencao: "/planosDeManutencao",
   planoDeManutencaoForm: "/planoDeManutencaoForm",
   testing: "/testingAreaMenu",
   recursoDisplayer: "/recursoDisplayer",
   cronogramaManutencao: "/cronogramaManutencao",
   templateDeDados: "/templateDeDados",
   mantenedores: "/mantenedores",
   tipoDeOrdemForm: "/tipoDeOrdem",
   registroDeCampoChart: "/registroDeCampoChart",
   estoqueForm: "/estoqueForm",
   estoques: "/estoques",
   composicaoForm: "/composicaoForm"



};

export const tipoFrequencia = ["DATE", "COUNTER"];
export const estadoStatus = ['PENDENTE', 'APROVADO', 'REPROVADO']
export const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
export const escala = ["DIA", "HORAS", "MINUTOS", "SEGUNDOS", "MILISEGUNDOS"]
export const tiposDeCampo = [{ nome: 'Textual', type: "text" }, { nome: 'Numérico', type: "number" }, { nome: 'Tempo', type: "time" }, { nome: 'ƒ(x)', type: "formula" }]
export const statusColor = (estado) => {


   if (estado === 'PENDENTE') return { color: 'orange', fontWeight: 'bold' };
   if (estado === 'REPROVADO') return { color: 'red', fontWeight: 'bold' };
   if (estado === 'APROVADO') return { color: 'green', fontWeight: 'bold' };
};
export const colors = {
   vermelhoPastel: { H: '#FAA0A0', R: "rgba(250, 160, 160, 0.5)" },
   verdePastel: { H: '#A4FBA6', R: "rgba(164,251,166, 0.5)" },
   cinzaClaro: { H: '#d1d0cd', R: "rgba(143, 142, 140, 0.3)" },
   amareloClaro: { H: '#f5d164', R: "rgba(143, 142, 140, 0.3)" },
   azulClaro: { H: '#6dd1ed', R: "rgba(143, 142, 140, 0.3)" },
   amareloEscuro: { H: '#eecb06', R: "rgba(238, 203, 6, 0.5)" }
}

/* export const labels = (language) => {
   return {Valor: {}}
} */

export const version = "1.1.0";

