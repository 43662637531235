import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		acao: '',
		trocas: [],
	},
};

const manutencaoReducer = createSlice({
	name: 'manutencaoReducer',
	initialState,
	reducers: {
		setManutencoes(state, action) {
			state.manutencoes = action.payload;
		},
		setAcao(state, action) {
			state.form.acao = action.payload;
		},

		addTrocas(state, action) {
			state.form.trocas = [...state.form.trocas, action.payload];
		},
		setTrocas(state, action) {
			state.form.trocas = action.payload;
		},

		markManutencao(state, action) {
			state.manutencoes[action.payload.index] = action.payload.mark;
		},
		setIsLoaded(state, action) {
			state.isLoaded = action.payload;
		},
		populateToEditManutencao(state, action) {
			state.form = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
		clearManutencao(state, action) {
			state.form = initialState.form;
		},
	},
});

export const {
	setManutencoes,
	markManutencao,
	clear : clearManutencaoForm,
	clearManutencao,
	setIsLoaded,
	setAcao,
	setPlanoDeManutencao,
	addTrocas,
	populateToEditManutencao,
	setTrocas,
} = manutencaoReducer.actions;
export default manutencaoReducer.reducer;
