import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		nome: '',
		descricao: '',
		recursoRef: null,
		dimensoes: [],
		recursoTag: {
			tag:{
				descricao: ''
			}
		},
		unidadesDeComponente: [],
		classesDeRecurso: [],
		groupLevel: '',
		branchToAppendId: null,
		branchLevel: null,
		level : null,
		active : true
	},
	recursosOps: [],
};

const recursoReducer = createSlice({
	name: 'recursoReducer',
	initialState,
	reducers: {
		setNome(state, action) {
			state.form.nome = action.payload;
		},
		setDescricaoTag(state, action) {
			state.form.recursoTag.tag.descricao = action.payload;
		},
		setDescricao(state, action) {
			state.form.descricao = action.payload;
		},
		setIsActive(state, action) {
			state.form.active = action.payload;
		},
		setGrupo(state, action) {
			state.form.grupoId = action.payload;
		},
		setParametros(state, action) {
			state.form.parametros = action.payload;
		},
		setComposicoes(state, action) {
			state.form.composicoes = action.payload;
		},
		setDimensoes(state, action) {
			state.form.dimensoes = action.payload;
		},
		setUnidadesDeComponente(state, action) {
			state.form.unidadesDeComponente = action.payload;
		},
		setClassesDeRecurso(state, action) {
			state.form.recursoClasses = action.payload;
		},
		setClasseSelected(state, action) {
			state.form.classeSelected = action.payload;
		},
		setGroupLevel(state, action) {
			state.form.groupLevel = action.payload;
		},
		setRecursosDoGrupo(state, action) {
			state.form.recursosDoGrupo = action.payload;
		},
		setRecursosOps(state, action) {
			state.recursosOps = action.payload;
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		populateToEditRecurso(state, action) {
			state.form = action.payload;
		},


		setBranchToAppendId(state, action) {
			state.form.branchToAppendId = action.payload;
		},
		setBranchLevel(state, action) {
			state.form.branchLevel = action.payload;
		},
		setLevelRecurso(state, action) {
			state.form.level = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
	},
});

export const {
	setNome,
	setDescricao,
	setBranchToAppendId,
	setBranchLevel,
	setRecursosOps,
	setGrupo,
	setParametros,
	setDimensoes,
	setUnidadesDeComponente,
	setClassesDeRecurso,
	setClasseSelected,
	setComposicoes,
	setGroupLevel,
	populateToEdit,
	setLevelRecurso,
	setRecursosDoGrupo,
	setIsActive,
	populateToEditRecurso,
	setDescricaoTag,
	clear : clearRecursoForm,
} = recursoReducer.actions;
export default recursoReducer.reducer;
