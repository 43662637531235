import React from "react"
import { Container } from "react-bootstrap"
import { GrFormPreviousLink } from "react-icons/gr"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import MenuBar from "../Components/MenuBar"
import { logOut } from "../Reducers/globalConfigReducer"
import AcessoNaoPermitido from "../Screens/AcessoNaoPermitido"
import { pathToHeader } from "../Services/stringUtils"
import useNavigator from "../hooks/useNavigator"
import { paths } from "../models/constantes"

export const withMenuBar = (Component) => {
	const WithMenuBar = (props) => {
		const toast = useToasts()
		const history = useNavigator()
		const global = useSelector((state) => state.global)
		const pageLocation = global.pageLocation
		const userId = global.userId
		const isAuth = global.isAuth
		
		let prps = { pageLocation, toast, userId, ...props }
		const dispatch = useDispatch()
		const { pathname } = useLocation()
		const avoidLocations = ["/Login", "/", "/Home"]

		const validateLocation = () => {
			if (isAuth && !global.role) dispatch(logOut())
			if (avoidLocations.includes(pathname)) return true
			if (isAuth && global?.role) {
				const currentUserFrontEndPerms =
					global?.role?.permissao[0].frontEndPerms

				const filteredPerm = currentUserFrontEndPerms.find((cUFEP) => {
					let filteredLocation = `/${cUFEP.locationBloqueado}`
					return filteredLocation == pathname
				})
				if (filteredPerm) {
					if (filteredPerm.componentesBloqueados.length > 0) {
						return true
					} else return false
				} else return true
			}
			return true
		}

		return (
			<>
				<MenuBar></MenuBar>
					{isAuth && (
						<GrFormPreviousLink
							className="prev_button"
							onClick={() => history(-1)}
							style={{ marginLeft: "1%" }}
							size={40}
						/>
					)}
					<div style={{marginLeft: '5%', marginRight: "5%"}}>

					<>
						{pathname != paths.acessoNaoPermitido ? (
							<h2 style={{ textAlign: "center" }}>
								{" "}
								{pathToHeader(pathname)}
							</h2>
						) : (
							""
						)}
						{validateLocation() ? (
							<Component {...prps} />
						) : (
							<AcessoNaoPermitido />
						)}
					</>
					</div>
				
			</>
		)
	}
	return WithMenuBar
}
