import { createSlice } from "@reduxjs/toolkit"

/* public Long processoId;
public List<linhaDePlanoDeControleForm> linhasPlanoDeControle;
public List<Long> fluxosId; */

const initialState = {
	form: {
		operacao: "",
		posicao: "",
		recurso: null,
		recursoId: null,
		controles: [],
		//        index: false
	},
	addingControle: false,
}

const linhaDePlanoDeControleReducer = createSlice({
	name: "linhaDePlanoDeControleReducer",
	initialState,
	reducers: {
		setOpercaoLinhaDePlanoDeControle(state, action) {
			state.form.operacao = action.payload
			state.form.operacaoId = action.payload.id
		},
		setHasFluxo(state, action) {
			state.form.hasFluxo = action.payload
		},
		setRecursoLinhaDePlanoDeControle(state, action) {
			state.form.recurso = action.payload
			state.form.recursoId =
				action.payload != null ? action.payload.id : null
		},
		setUpdateControleLinhaDePlanoDeControle(state, action) {
			let {controleForm, index} = action.payload
			state.form.controles[index] = controleForm
		},
		setAddingControle(state, action) {
			state.addingControle = action.payload
		},
		setControleDeProcessoLinhaDePlanoDeControle(state, action) {
			state.form.controles = action.payload
		},
		setPosicaoOperacao(state, action) {
			state.form.posicao = action.payload
		},
		populateToEditlinhaDePlanoDeControle(state, action) {
			state.form = action.payload
		},
		setParametroLinhaDePlanoControle(state, action) {
			state.form.parametro = action.payload
		},
		setFrequenciaLinhaDePlanoDeCOntrole(state, action) {
			state.form.frequencia = action.payload
		},
		clear(state) {
			state.form = initialState.form
		},
	},
})

export const {
	setFrequenciaLinhaDePlanoDeCOntrole,
	setOpercaoLinhaDePlanoDeControle,
	setControleDeProcessoLinhaDePlanoDeControle,
	setPosicaoOperacao,
	populateToEditlinhaDePlanoDeControle,
	setParametroLinhaDePlanoControle,
	clear: clearLinhaDePlanoDeControleForm,
	setHasFluxo,
	setRecursoLinhaDePlanoDeControle,
	setAddingControle,
	setUpdateControleLinhaDePlanoDeControle
} = linhaDePlanoDeControleReducer.actions
export default linhaDePlanoDeControleReducer.reducer
