import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		estoqueId:null ,
		nomeEstoque: '',
		movimentacoes: [],
	},
};

const kardexReducer = createSlice({
	name: 'kardexReducer',
	initialState,
	reducers: {
		setNomeEstoque(state, action) {
			state.form.nomeEstoque = action.payload;
		},
		setMovimentacoes(state, action) {
			state.form.movimentacoes = action.payload;
		},

		populateToEdit(state, action) {
			state.form = action.payload;
		},
		clear(state) {
			state.form = initialState.form;
		},
	},
});

export const {
	setNomeEstoque,
	setMovimentacoes,
	populateToEdit: populateToEditKardex,
	clear: clearKardex,
	setEstoques,
} = kardexReducer.actions;
export default kardexReducer.reducer;
