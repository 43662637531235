import { createSlice } from '@reduxjs/toolkit'
import { move } from '../Services/arraysUtils'

const initialState = {
	formularioForm: {
		campos: [],
		descricao: '',
		parametroToMap: '',
	},
	formularios: {
		page: 0,
		size: 0,
		data: [],
	},
	respostasFormulario: {
		formularioId: '',
		respostas: [],
		page: 0,
		size: 0,
	},
}

const formularioReducer = createSlice({
	name: 'formulario',
	initialState,
	reducers: {
		setCampo(state, action) {
			state.formularioForm.campos = action.payload
		},
		setDescricao(state, action) {
			state.formularioForm.descricao = action.payload
		},
		setParametroToMap(state, action) {
			state.formularioForm.parametroToMap = action.payload
		},
		setOpcao(state, action) {
			let { campoIndex, containerIndex, opcaoIndex, texto } = action.payload
			state.formularioForm.campos[campoIndex].containerDeOpcoes[containerIndex].opcoes[
				opcaoIndex
			].valor = texto
		},
		deleteOpcao(state, action) {
			let { campoIndex, containerIndex, opcaoIndex } = action.payload
			state.formularioForm.campos[campoIndex].containerDeOpcoes[containerIndex].opcoes.splice(
				opcaoIndex,
				1
			)
		},
		moveContainerUp(state, action) {
			let { campoIndex, containerIndex } = action.payload
			move(
				state.formularioForm.campos[campoIndex].containerDeOpcoes,
				containerIndex,
				containerIndex - 1
			)
		},
		moveContainerDown(state, action) {
			let { campoIndex, containerIndex } = action.payload
			move(
				state.formularioForm.campos[campoIndex].containerDeOpcoes,
				containerIndex,
				containerIndex + 1
			)
		},
		addRegraDeValidacao(state, action) {
			let { campoIndex, containerIndex, regrasValidacao } = action.payload
			if (
				state.formularioForm.campos[campoIndex].containerDeOpcoes[containerIndex].regrasValidacao
			) {
				state.formularioForm.campos[campoIndex].containerDeOpcoes[
					containerIndex
				].regrasValidacao.push(...regrasValidacao)
			} else {
				state.formularioForm.campos[campoIndex].containerDeOpcoes[containerIndex].regrasValidacao =
					new Array()
				state.formularioForm.campos[campoIndex].containerDeOpcoes[
					containerIndex
				].regrasValidacao.push(...regrasValidacao)
			}
		},
		setRequired(state, action) {
			let { campoIndex, containerIndex, marked } = action.payload
			state.formularioForm.campos[campoIndex].containerDeOpcoes[containerIndex].isRequired = marked
		},
		deleteRegra(state, action) {
			let { campoIndex, containerIndex, regraIndex } = action.payload
			state.formularioForm.campos[campoIndex].containerDeOpcoes[containerIndex].regrasValidacao =
				state.formularioForm.campos[campoIndex].containerDeOpcaos[
					containerIndex
				].regrasValidacao.filter((regra, index) => index != regraIndex)
		},
		setFormularios(state, action) {
			state.formularios.data = action.payload
		},
		setRespostasFormulario(state, action) {
			state.respostasFormulario.respostas = action.payload
		},
		populateToEdit(state, action) {
			state.formularioForm = action.payload
		},
		clear(state) {
			state.formularioForm = {}
		},
	},
})

export const {
	setCampo,
	setDescricao,
	setFormularios,
	setRespostasFormulario,
	populateToEdit,
	setRequired,
	deleteRegra,
	addRegraDeValidacao,
	setParametroToMap,
	moveContainerDown,
	setOpcao,
	deleteOpcao,
	moveContainerUp,
	clear : clearFormularioForm,
} = formularioReducer.actions
export default formularioReducer.reducer
