import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		nome: '',
		horaInicio: '',
		horaFim: '',
        posicaoDoTurno:''
	},
};

const turnoReducer = createSlice({
	name: 'turnoReducer',
	initialState,
	reducers: {
		setHoraInicio(state, action) {
			state.form.horaInicio = action.payload;
		},

		setHoraFim(state, action) {
			state.form.horaFim = action.payload;
		},
		setNomeTurno(state, action) {
			state.form.nome = action.payload;
		},
		setPosicaoDoTurno(state, action) {
			state.form.posicaoDoTurno = action.payload;
		},
        clear(state, action) {
            state.form = initialState.form
        },

	},
});

export const { setHoraFim, setHoraInicio, setNomeTurno, setPosicaoDoTurno, clear : clearTurnoForm } = turnoReducer.actions;
export default turnoReducer.reducer;
