import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    cachedObj: {}
}


const cachedReducer = createSlice({
    name: 'cachedReducer',
    initialState,
    reducers: {
        updateCached(state, action) {
            let { cachedKey, objToCache, equalKey } = action.payload
            let targetCachedList = state.cachedObj[cachedKey]
            if (targetCachedList) {
                let targetIndex
                if (equalKey) {
                    targetIndex = targetCachedList.findIndex(cObj => cObj[equalKey] == objToCache[equalKey])
                } else {
                    targetIndex = targetCachedList.findIndex(cObj => cObj == objToCache)
                }
                targetCachedList[targetIndex] = objToCache
            }
        },
        addCached(state, action) {
            let { cachedKey, objToCache, equalKey, countLimit } = action.payload
            let cachedList = state.cachedObj[cachedKey]
            if (Array.isArray(objToCache)) {
                for (let obj of objToCache) {
                    let targetIndex = cachedList?.findIndex(cObj => cObj[equalKey] == obj[equalKey])
                    if (cachedList) {
                        if ((countLimit) && (countLimit >= cachedList.length)) {
                            cachedList.pop()
                            state.cachedObj[cachedKey] = cachedList
                        }
                        if (targetIndex != -1) state.cachedObj[cachedKey][targetIndex] = obj
                        else state.cachedObj[cachedKey] = [...state.cachedObj[cachedKey], obj]
                    } else {
                        state.cachedObj[cachedKey] = [obj]
                    }
                }

            } else {
                let targetIndex = cachedList?.findIndex(cObj => cObj[equalKey] == objToCache[equalKey])
                if (cachedList) {
                    if ((countLimit) && (countLimit >= cachedList.length)) {
                        cachedList.pop()
                        state.cachedObj[cachedKey] = cachedList
                    }
                    if (targetIndex != -1) state.cachedObj[cachedKey][targetIndex] = objToCache
                    else state.cachedObj[cachedKey] = [...state.cachedObj[cachedKey], objToCache]
                } else {
                    state.cachedObj[cachedKey] = [objToCache]
                }
            }

        },
        eraseCached(state, action) {
            let { cachedKey, objToCache, equalKey, countLimit } = action.payload
            state.cachedObj[cachedKey] = objToCache
        },
        removeCached(state, action) {
            let { cachedKey, objToCache, equalKey } = action.payload
            let targetCachedList = state.cachedObj[cachedKey]
            if (targetCachedList) {
                let targetIndex
                if (equalKey) {
                    targetIndex = targetCachedList.findIndex(cObj => cObj[equalKey] == objToCache[equalKey])
                } else {
                    targetIndex = targetCachedList.findIndex(cObj => cObj == objToCache)
                }
                delete targetCachedList[targetIndex]
            }
        },
    },
})

export const { updateCached,
    addCached,
    eraseCached,
    removeCached } = cachedReducer.actions
export default cachedReducer.reducer