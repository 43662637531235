import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		ordensDeManutencao: [],
	},
}

const cronogramaManutencaoReducer = createSlice({
	name: "cronogramaManutencaoReducer",
	initialState,
	reducers: {
		setOrdensDeManutencaoCronograma(state, action) {
			state.form.ordensDeManutencao = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setOrdensDeManutencaoCronograma,
	clear: clearCronogramaManutencao,
	populateToEdit: populateCronogramaManutencao,
} = cronogramaManutencaoReducer.actions
export default cronogramaManutencaoReducer.reducer
