import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        proporcaoDeTroca: "",
        trocaClassesDeComponentes:[ ]
    }
}


const trocaReducer = createSlice({
    name: 'trocaReducer',
    initialState,
    reducers: {
        setProporcaoDeTroca(state, action) {
            state.form.proporcaoDeTroca = action.payload
        },
        setClasseDeComponente(state, action) {
            state.form.trocaClassesDeComponentes = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state) {
            state.form = initialState
        },
        clearTroca(state) {
            state.form = initialState
        },

    },
})

export const { setProporcaoDeTroca,clear, clearTroca,setClasseDeComponente, populateToEdit, addClasseDeComponente } = trocaReducer.actions
export default trocaReducer.reducer