import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		nome: '',
		unidadesMaterial: [],
		componenteClasses: [],
	},
	picker: {
		componente: null,
		quantidade: '',
		unidade: '',
		unidadeId: '',
	},
}

const componenteReducer = createSlice({
	name: 'componenteReducer',
	initialState,
	reducers: {
		setComponentePicker(state, action) {
			state.picker.componente = action.payload
		},
		setQuantidade(state, action) {
			state.picker.quantidade = action.payload
		},
		setNome(state, action) {
			state.form.nome = action.payload
		},
		setMaterial(state, action) {
			state.form.material = action.payload
			state.form.materialId = action.payload.id

		},
		setUnidadeDeComponente(state, action) {
			state.picker.unidade = action.payload
			state.picker.unidadeId = action.payload.id
		},
		setUnidadeMateriais(state, action) {
			state.form.unidadesMaterial = action.payload
		},
		setComponenteUnidadeMaterials(state, action) {
			state.form.componenteUnidadeMaterials = action.payload
		},
		setClassesDeComponente(state, action) {
			state.form.componenteClasses = action.payload
		},
		populateToEditComponentePicker(state, action) {
			state.picker = action.payload
		},
		clearComponentePicker(state, action) {
			state.picker = initialState.picker
		},
		populateToEditComponente(state, action) {
			state.form = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setNome,
	setUnidadeMateriais,
	setUnidadeDeComponente,
	setComponenteUnidadeMaterials,
	clearComponentePicker,
	populateToEditComponentePicker,
	setComponentePicker,
	setQuantidade,
	setClassesDeComponente,
	populateToEdit,
	setMaterial,
	clear : clearComponenteForm,
} = componenteReducer.actions
export default componenteReducer.reducer
