import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		itensDeComposicao: [],
	}

}

const composicaoReducer = createSlice({
	name: 'composicaoReducer',
	initialState,
	reducers: {
		setUnidadesDeMaterialComposicao(state, action) {
			state.form.itensDeComposicao = action.payload
		},
		updateItemDeComposicao(state, action) {
			state.form.itensDeComposicao[action.payload.index] = action.payload
		},
		populateToEditComposicao(state, action) {
			state.form = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clearComposicao(state) {
			state.form = initialState.form
		},
	},
})

export const { setUnidadesDeMaterialComposicao, clearComposicao, populateToEditComposicao,
	populateToEdit,updateItemDeComposicao } = composicaoReducer.actions
export default composicaoReducer.reducer
