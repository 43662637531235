import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        
    }
}


const dimensaoReducer = createSlice({
    name: 'dimensaoReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.form.nome = action.payload
        },
        setFormula(state, action) {
            state.form.formula = action.payload
        },
        setValor(state, action) {
            state.form.valor = action.payload
        },
        setUnidade(state, action) {
            state.form.unidade = action.payload
        },
        populateToEditDimensaoReducer(state, action) {
            state.form = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state) {
            state.form = {}
        },

    },
})

export const { setNome, setFormula, setValor,populateToEditDimensaoReducer, setUnidade, populateToEdit, clear : clearDimensaoForm } = dimensaoReducer.actions
export default dimensaoReducer.reducer