import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		userId: '',
		descricao: '',
		indexRegistro: '', //propriedade apenas no front-end
		registroDeCampoId: null, //propriedade apenas no front-end
	},
	justificativas: [],
};

const justificativaReducer = createSlice({
	name: 'justificativaReducer',
	initialState,
	reducers: {
		setDescricaoJustificativa(state, action) {
			state.form.descricao = action.payload;
		},

		setIndexRegistro(state, action) {
			state.form.indexRegistro = action.payload;
		},
		setRegistroDeCampoId(state, action) {
			state.form.registroDeCampoId = action.payload;
		},
		setUpdateJustificativa(state, action) {
			let { justificativa, index } = action.payload;
			state.justificativas[index] = justificativa;
		},
		setJustificativas(state, action) {
			const justificativas = state.justificativas;
			if (!justificativas) {
				state.justificativas = action.payload;
			} else {
				state.justificativas = [...state.justificativas, action.payload];
			}
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		populateJustificativas(state, action) {
			state.justificativas = action.payload;
		},
		clear(state) {
			state.form = initialState.form;
			state.justificativas = initialState.justificativas;
		},
	},
});

export const {
	setDescricaoJustificativa,
	populateToEdit : populateToEditJustificativa,
	setJustificativas,
	setIndexRegistro,
	clear : clearJustificativaForm,
	setRegistroDeCampoId,
	setUpdateJustificativa,
	populateJustificativas,
} = justificativaReducer.actions;
export default justificativaReducer.reducer;
