import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		consumos: [],
		dataDeRegistro: '',
		estoqueSaida: '',
	},
	authorized: false,
	editingMode: false,
}

const registroDeTarefaReducer = createSlice({
	name: 'registroDeTarefaReducer',
	initialState,
	reducers: {
		setQuantidadeConsumo(state, action) {
			let { indexItem, indexRequisicao, quantidade } = action.payload

			state.form.requisicoesMaterial[indexRequisicao].itensDeRequisicao[
				indexItem
			].consumos[0].unidadeMaterial.quantidade = +quantidade
		},
		setUpdateSingleConsumo(state, action) {
			let { indexItem, indexRequisicao } = action.payload

			state.form.requisicoesMaterial[indexRequisicao].itensDeRequisicao[indexItem].consumos[0] =
				action.payload
		},
		setDataDoConsumo(state, action) {
			let { indexItem, indexRequisicao, data } = action.payload

			state.form.requisicoesMaterial[indexRequisicao].itensDeRequisicao[
				indexItem
			].consumos[0].dataDoConsumo = data
		},
		updateSingleItemDeReq(state, action) {
			let { indexRequisicao } = action.payload
			let index = state.form.requisicoesMaterial[indexRequisicao].itensDeRequisicao.findIndex(
				(iR) => iR.id == action.payload.id
			)

			state.form.requisicoesMaterial[indexRequisicao].itensDeRequisicao[index] = action.payload
		},
		setDataDeRegistro(state, action) {
			state.form.dataDeRegistro = action.payload
		},
		populateToEditReg(state, action) {
			state.form = action.payload
		},
		setUpdateMaterial(state, action) {
			let { indexItem, indexRequisicao } = action.payload
			state.form.requisicoesMaterial[indexRequisicao].itensDeRequisicao[indexItem].unidadeMaterial =
				action.payload
		},
		setJustificativaRegistroTarefa(state, action) {
			state.form.justificativa = action.payload
		},
		setIsAuthorized(state, action) {
			state.authorized = action.payload
		},
		setEstoqueSaida(state, action) {
			state.form.estoqueSaida = action.payload
		},
		setTarefaRegistroDeTarefa(state, action)  {
			state.form.tarefa = action.payload
		},
		setUpdateRealizadoUMTarefa(state, action)  {
			let { index, realizado } = action.payload
			state.form.tarefa.tarefaUnidadesMateriais[index].unidadeMaterial.realizado = realizado
		},
		clear(state, action) {
			state.form = initialState
		},
		setEditingMode(state, action) {
			state.editingMode = action.payload
		},
	},
})

export const {
	setQuantidadeConsumo,
	populateToEditReg,
	clear : clearRegistroDeTarefaForm,
	setDataDoConsumo,
	setJustificativaRegistroTarefa,
	setDataDeRegistro,
	setIsAuthorized,
	setEditingMode,
	setUpdateMaterial,
	updateSingleItemDeReq,
	setUpdateSingleConsumo,
	setEstoqueSaida,
	setTarefaRegistroDeTarefa,
	setUpdateRealizadoUMTarefa
} = registroDeTarefaReducer.actions
export default registroDeTarefaReducer.reducer
