import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		descricao: '',
		tipoDeOrdem: '',
	},
	picker: {
		descricao: '',
		tipoDeOrdem: '',
	},
};

const modoDeFalhaReducer = createSlice({
	name: 'modoDeFalhaReducer',
	initialState,
	reducers: {
		setDescricao(state, action) {
			state.form.descricao = action.payload;
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		populateToEditPicker(state, action) {
			state.picker = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
		setTipoDeOrdem(state, action) {
			state.form.tipoDeOrdem = action.payload;
		},
	},
});

export const { setDescricao, setRecurso, populateToEdit : populateToEditMDF, setConsumos, clear : clearModoDeFalhaForm, setTipoDeOrdem, populateToEditPicker : populateToEditModoDeFalhaPicker } =
	modoDeFalhaReducer.actions;
export default modoDeFalhaReducer.reducer;
