import { createSlice } from '@reduxjs/toolkit'
import { move } from '../Services/arraysUtils'
import RegistroDeEntrada from '../models/RegistroDeEntrada'

const initialState = {
	resposta: null,
	registrosDeEntrada: {},
	respostas: [],
	erroState: {},
}

const respostaFormularioReducer = createSlice({
	name: 'respostaFormulario',
	initialState,
	reducers: {
		setFormulario(state, action) {
			state.formulario = action.payload
		},
		setResposta(state, action) {
			state.resposta = action.payload
		},
		setRespostasDoFormulario(state, action) {
			state.respostas = action.payload
		},
		setRegistroDeEntradaBySelectBox(state, action) {
			let { container, valor } = action.payload
			state.registrosDeEntrada[container.id] = new RegistroDeEntrada(container.id, null, valor)
		},
		setRegistroDeEntradaByRadioBox(state, action) {
			let { containerId, valor } = action.payload
			let contains = false
			state.registrosDeEntrada.map((reg) => {
				if (reg.containerId == containerId) {
					state.registrosDeEntrada.valor = valor
					contains = true
				}
				return reg
			})
			if (!contains) {
				state.registrosDeEntrada.push(new RegistroDeEntrada(containerId, null, valor))
			}
		},
		setRegistroDeEntradaByCheckBox(state, action) {
			let { containerId, opcaoId, valor } = action.payload
			let contains = false
			state.registrosDeEntrada.map((reg) => {
				if (reg.containerId == containerId && reg.opcaoId == opcaoId) {
					state.registrosDeEntrada.valor = valor
					contains = true
				}
				return reg
			})
			if (!contains) {
				state.registrosDeEntrada.push(new RegistroDeEntrada(containerId, opcaoId, valor))
			}
		},
		setRegistrosDeEntrada(state, action) {
			state.registrosDeEntrada = action.payload
		},
		setRegistroDeEntradaByTextBox(state, action) {
			let { container, valor } = action.payload
			state.registrosDeEntrada[container.id] = new RegistroDeEntrada(container.id, null, valor)
		},
		setErros(state, action) {
			let { container, erros } = action.payload
			state.erroState[container.id] = erros
		},
	},
})

export const {
	setFormulario,
	setRegistrosDeEntrada,
	setErros,
	setResposta,
	setRespostasDoFormulario,
	setRegistroDeEntradaBySelectBox,
	setRegistroDeEntradaByRadioBox,
	setRegistroDeEntradaByCheckBox,
	setRegistroDeEntradaByTextBox,
} = respostaFormularioReducer.actions
export default respostaFormularioReducer.reducer
