import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
const PermissionContainer = ({ component, menubar }) => {
	const userRoles = useSelector((state) => state.global.role)
	const componentRef = useRef(null)
	const { addToast } = useToasts()

	useEffect(() => {
		async function validateFrontEndPerms() {
			const compId = componentRef.current.id
			for (let frontEndPerm of userRoles.permissao[0].frontEndPerms) {
				let componentesBloqueados = frontEndPerm.componentesBloqueados
				if (componentesBloqueados.includes(compId)) {
					componentRef.current.hidden = true
				}
				if (menubar) {
					let newCompId = compId.replace("/", '')
					if (frontEndPerm.locationBloqueado == newCompId) {
						if (componentesBloqueados.length > 0) {
							componentRef.current.hidden = false
						} else componentRef.current.hidden = true
					}
				}
			}
		}
		if (componentRef) validateFrontEndPerms()
	}, [componentRef])

	return component(componentRef)
}

export default PermissionContainer
