import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        id: null,
        nome: "",
    }
}


const processoReducer = createSlice({
    name: 'processoReducer',
    initialState,
    reducers: {
        setNome(state, action) {
        state.form.nome = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
            state.form = initialState.form
        },

    },
})

export const { setNome, populateToEdit, clear : clearProcessoForm} = processoReducer.actions
export default processoReducer.reducer