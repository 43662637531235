import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        saldos : []
    },
    estoques: []
}


const estoqueReducer = createSlice({
    name: 'estoqueReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.form.nome = action.payload
        },
        setSaldos(state, action) {
            state.form.saldos = action.payload
        },
        setAddSaldoEstoque(state, action) {
            state.form.saldos = [...state.form.saldos, action.payload]
        },
        setEstoques(state, action) {
            state.estoques = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state) {
            state.form = initialState.form
        },

    },
})

export const { setNome, setSaldos, populateToEdit:populateToEditEstoque, clear: clearEstoqueReducer, setEstoques, setAddSaldoEstoque   } = estoqueReducer.actions
export default estoqueReducer.reducer