import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    form: {
        acao : "",
        parametro : "",
        campoDeVerificacao : "",
        tipoDeCorrecao : "",
        calculosDeCorrecao: [],
        acaoId : "",
        parametroId: "",
        campoDeVerificacaoId : ""
    },
};

const regraDeCorrecaoReducer = createSlice({
    name: 'regraDeCorrecaoReducer',
    initialState,
    reducers: {
        setAcao(state, action) {
            state.form.acao = action.payload;
            state.form.acaoId = action.payload.id;
        },
        setParametro(state, action) {
            state.form.parametro = action.payload;
            state.form.parametroId = action.payload.id;
        },
        setCampoDeVerificacao(state, action) {
            state.form.campoDeVerificacao = action.payload;
            state.form.campoDeVerificacaoId = action.payload.id;
        },
       
        setCalculoDeCorrecao(state, action) {
            state.form.calculosDeCorrecao = action.payload;
        },
        setTipoDeCorrecao(state, action) {
            state.form.tipoDeCorrecao = action.payload;
        },
        populateToEdit(state, action) {
			state.form = action.payload
		},
        clearRegraDeCorrecao(state, action) {
            state.form = initialState.form;
        },
    },
});

export const {
   setAcao,setParametro,setCalculoDeCorrecao,setCampoDeVerificacao,setTipoDeCorrecao, clearRegraDeCorrecao, populateToEdit
} = regraDeCorrecaoReducer.actions;
export default regraDeCorrecaoReducer.reducer;
