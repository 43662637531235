import { combineReducers } from 'redux';
import branchLevelReducer from './branchLevelReducer';
import classeDeRecursoReducer from './classeDeRecursoReducer';
import alarmeReducer from './commons-reducers/alarmeReducer';
import contadorReducer from './commons-reducers/contadorReducer';
import frequenciaReducer from './commons-reducers/frequenciaReducer';
import justificativaReducer from './commons-reducers/justificativaReducer';
import registroReducer from './commons-reducers/registroReducer';
import setorReducer from './commons-reducers/setorReducer';
import statusReducer from './commons-reducers/statusReducer';
import turnoReducer from './commons-reducers/turnoReducer';
import unidadeReducer from './commons-reducers/unidadeReducer';
import componenteReducer from './componenteReducer';
import consumoReducer from './consumoReducer';
import dynamicFormsReducer from './dyanamicForms';
import estoqueReducer from './suprimentos-reducers/estoqueReducer';
import FormularioReducer from './formularioReducer';
import globalConfig from './globalConfigReducer';
import grupoReducer from './gestao-reducers/grupoReducer';
import acaoReducer from './manutencao-reducers/acaoReducer';
import arvoreEstruturalReducer from './manutencao-reducers/arvoreEstruturalReducer';
import classeComponenteReducer from './manutencao-reducers/classeDeComponenteReducer';
import dimensaoReducer from './manutencao-reducers/dimensaoReducer';
import mantenedorReducer from './manutencao-reducers/mantenedorReducer';
import manutencaoReducer from './manutencao-reducers/manutencaoReducer';
import materialReducer from './manutencao-reducers/materialReducer';
import modoDeFalhaReducer from './manutencao-reducers/modoDeFalhaReducer';
import ordemDeManutencaoReducer from './manutencao-reducers/ordemDeManutencaoReducer';
import ordemDeServicoReducer from './manutencao-reducers/ordemDeServicoReducer';
import planoDeManutencaoReducer from './manutencao-reducers/planoDeManutencaoReducer';
import recursoReducer from './manutencao-reducers/recursoReducer';
import registroDeTarefaReducer from './manutencao-reducers/registroDeTarefaReducer';
import solicitacaoDeServicoReducer from './manutencao-reducers/solicitacaoDeServicoReducer';
import tarefaReducer from './manutencao-reducers/tarefaReducer';
import tipoDeOrdemReducer from './manutencao-reducers/tipoDeOrdemReducer';
import trocaReducer from './manutencao-reducers/trocaReducer';
import movimentacaoReducer from './suprimentos-reducers/movimentacaoReducer';
import pickersReducer from './pickersReducer';
import FolhaDeVerificacaoReducer from './processo-reducers/FolhaDeVerificacaoReducer';
import cadernoDeVerificacaoReducer from './processo-reducers/cadernoDeVerificacaoReducer';
import controleReducer from './processo-reducers/controleReducer';
import especificacaoReducer from './processo-reducers/especificacaoReducer';
import fluxoDeProcessoReducer from './processo-reducers/fluxoDeProcessoReducer';
import limiteDeControleReducer from './processo-reducers/limiteDeControleReducer';
import linhaDePlanoDeControleReducer from './processo-reducers/linhaDePlanoDeControleReducer';
import operacaoReducer from './processo-reducers/operacaoReducer';
import parametroReducer from './processo-reducers/parametroReducer';
import planoDeControleReducer from './processo-reducers/planoDeControleReducer';
import processoReducer from './processo-reducers/processoReducer';
import respostaFormulario from './respostaFormularioReducer';
import unidadeMaterialReducer from './suprimentosReducer/unidadeMaterialReducer';
import trocaComponenteReducer from './trocaComponenteReducer';

import webProviderReducer from './webProviderReducer';
import kardexReducer from './suprimentos-reducers/kardexReducer';
import cachedReducer from './commons-reducers/cachedReducer';
import timeReducer from './gestao-reducers/timeReducer';
import teamMemberReducer from './gestao-reducers/teamMemberReducer';
import workGroupReducer from './gestao-reducers/workGroupReducer';
import requisicaoMaterialReducer from './suprimentos-reducers/requisicaoMaterialReducer';
import genericPickerReducer from './genericPickerReducer';
import composicaoReducer from './composicaoReducer';
import cronogramaManutencaoReducer from './manutencao-reducers/CronogramaManutencaoReducer';
import regraDeCorrecaoReducer from './processo-reducers/regraDeCorrecaoReducer';
import calculoDeCorrecaoReducer from './processo-reducers/calculoDeCorrecaoReducer';
import correcaoReducer from './processo-reducers/correcaoReducer';

const appReducer = combineReducers({
	global: globalConfig,
	formsReducer: dynamicFormsReducer,
	recurso: recursoReducer,
	grupo: grupoReducer,
	troca: trocaReducer,
	acao: acaoReducer,
	unidadeMaterial: unidadeMaterialReducer,
	classeDeRecurso: classeDeRecursoReducer,
	consumo: consumoReducer,
	solicitacaoDeServico: solicitacaoDeServicoReducer,
	ordemDeServico: ordemDeServicoReducer,
	pickers: pickersReducer,
	limiteDeControle: limiteDeControleReducer,
	especificacao: especificacaoReducer,
	parametro: parametroReducer,
	alarme: alarmeReducer,
	unidade: unidadeReducer,
	frequencia: frequenciaReducer,
	componente: componenteReducer,
	classeDeComponente: classeComponenteReducer,
	trocaComponente: trocaComponenteReducer,
	planoDeManutencao: planoDeManutencaoReducer,
	ordemDeManutencao: ordemDeManutencaoReducer,
	manutencao: manutencaoReducer,
	estoque: estoqueReducer,
	formulario: FormularioReducer,
	respostaDeFormulario: respostaFormulario,
	folhaDeVerificacao: FolhaDeVerificacaoReducer,
	status: statusReducer,
	movimentacao: movimentacaoReducer,
	arvoreEstrutural: arvoreEstruturalReducer,
	branchLevel: branchLevelReducer,
	planoDeControle: planoDeControleReducer,
	fluxoDeProcesso: fluxoDeProcessoReducer,
	operacao: operacaoReducer,
	processo: processoReducer,
	linhaDePlanoDeControle: linhaDePlanoDeControleReducer,
	modoDeFalha: modoDeFalhaReducer,
	material: materialReducer,
	tarefa: tarefaReducer,
	contador: contadorReducer,
	registro: registroReducer,
	cadernoDeVerificacao: cadernoDeVerificacaoReducer,
	dimensao: dimensaoReducer,
	mantenedor: mantenedorReducer,
	justificativa: justificativaReducer,
	turno: turnoReducer,
	setor: setorReducer,
	controle: controleReducer,
	registroDeTarefa: registroDeTarefaReducer,
	tipoDeOrdem: tipoDeOrdemReducer,
	
	webProvider: webProviderReducer,
	kardex: kardexReducer,
	cached: cachedReducer,
	team: timeReducer,
	teamMember: teamMemberReducer,
	workGroup: workGroupReducer,
	requisicaoMaterial: requisicaoMaterialReducer,
	picker: genericPickerReducer,
	composicao: composicaoReducer,
	cronogramaManutencao: cronogramaManutencaoReducer,
	regraDeCorrecao : regraDeCorrecaoReducer,
	calculoDeCorrecao : calculoDeCorrecaoReducer,
	correcao : correcaoReducer

});

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === 'eraseStore') {
		state.justificativa = undefined;
		state.frequencia = undefined;
		state.requisicaoMaterial = undefined;
		state.time = undefined;
		state.registroDeTarefa = undefined;
		state.setor = undefined;
		state.kardex = undefined;
		state.contador = undefined;
		state.registro = undefined;
		state.manutencao = undefined;
		state.ordemDeManutencao = undefined;
		state.mantenedor = undefined;
		state.planoDeManutencao = undefined;
		state.turno = undefined;
		state.global = undefined;
		state.teamMember = undefined;
		state.formsReducer = undefined;
		state.unidadeMaterial = undefined;
		state.recurso = undefined;
		state.grupo = undefined;
		state.acao = undefined;
		state.classeDeRecurso = undefined;
		state.consumo = undefined;
		state.solicitacaoDeServico = undefined;
		state.ordemDeServico = undefined;
		state.pickers = undefined;
		state.limiteDeControle = undefined;
		state.especificacao = undefined;
		state.parametro = undefined;
		state.alarme = undefined;
		state.unidade = undefined;
		state.tipoDeOrdem = undefined;
		state.componente = undefined;
		state.classeDeComponente = undefined;
		state.trocaComponente = undefined;
		state.ordemDeManutencao = undefined;
		state.manutencao = undefined;
		state.estoque = undefined;
		state.formulario = undefined;
		state.respostaDeFormulario = undefined;
		state.folhaDeVerificacao = undefined;
		state.cronogramaManutencao = undefined;
		state.status = undefined;
		state.movimentacao = undefined;
		state.arvoreEstrutural = undefined;
		state.branchLevel = undefined;
		state.planoDeControle = undefined;
		state.fluxoDeProcesso = undefined;
		state.operacao = undefined;
		state.processo = undefined;
		state.linhaDePlanoDeControle = undefined;
		state.modoDeFalha = undefined;
		state.material = undefined;
		state.tarefa = undefined;
		state.cadernoDeVerificacao = undefined;
		state.dimensao = undefined;
		state.controle = undefined;
		state.webProvider = undefined;
		state.cached = undefined
		state.picker = undefined
		state.composicao = undefined
		state.regraDeCorrecao = undefined
		state.calculoDecorrecao = undefined
		state.correcao = undefined
	}

	return appReducer(state, action);
};

export default rootReducer;
