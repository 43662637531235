import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		acao: "",
		status: "PENDENTE",
	},
	tarefas: [],
}

const tarefaReducer = createSlice({
	name: "tarefaReducer",
	initialState,
	reducers: {
		setAcao(state, action) {
			state.form.acao = action.payload
		},
		setUnidadesDeMaterial(state, action) {
			state.form.unidadesMateriais = action.payload
		},
		setAddJustificativaTarefa(state, action) {
			if (!state.form.tarefaJustificativas)
				state.form.tarefaJustificativas = []
			state.form.tarefaJustificativas = [
				...state.form.tarefaJustificativas,
				action.payload,
			]
		},
		setJustificativasTarefa(state, action) {
			state.form.tarefaJustificativas = action.payload
		},
		setUpdateJustificativaTarefa(state, action) {
			let { index } = action.payload
			state.form.tarefaJustificativas[index] = action.payload
		},
		setAddTarefa(state, action) {
			state.tarefas = [...state.tarefas, action.payload]
		},
		setAddTarefas(state, action) {
			state.tarefas = action.payload
		},

		setUpdateUnidadeMaterial(state, action) {
			if (!state.form.unidadesMateriais) state.form.unidadesMateriais = []
			let { index, unidadeMaterial } = action.payload

			state.form.unidadesMateriais[index] = unidadeMaterial
		},

		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setAcao,
	setUnidadesDeMaterial,
	populateToEdit,
	clear: clearTarefaForm,
	setUpdateUnidadeMaterial,
	setAddTarefa,
	setAddTarefas,
	setAddJustificativaTarefa,
	setJustificativasTarefa,
	setUpdateJustificativaTarefa,
} = tarefaReducer.actions
export default tarefaReducer.reducer
