import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        userId: "",
    },
    picker: {

    }
}

const mantenedorReducer = createSlice({
    name: 'mantenedorReducer',
    initialState,
    reducers: {
        setMantenedor(state, action) {
            state.form.userId = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        populateToEditPicker(state, action) {
            state.picker= action.payload
        },
        clear(state, action) {
            state.form = initialState.form
        },

    },
})

export const { setMantenedor ,populateToEdit, clear : clearMantenedorForm, populateToEditPicker: populateToEditMantenedorPicker } = mantenedorReducer.actions
export default mantenedorReducer.reducer