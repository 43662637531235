import { plusDate } from "../Services/auth"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	loadedOptions: [],
	loading: false,
	firstReload: true,
	isAuth: false,
	token: "",
	tokenExpiration: "",
	email: "",
	userId: "",
	googlKey: "AIzaSyAwUkhGE3_YB8cT4706OKT-xi3RpvnL014",
	portalSheetApi:
		"https://sheets.googleapis.com/v4/spreadsheets/1_RVYwW2QaWfaq3Ib-SOs6jo9qbGEbqh01rHRBrS2ewY/values",
	headers: [],
	isConectedSocket: null,
	prevPage: null,
	pageLocation: null,
	context: "",
	company: "",
	contextToUpdate: null,
	pickerAction: "",
	singlePick: false,
	role: "",
	socketConnection: false,
}

const globalConfig = createSlice({
	name: "globalConfig",
	initialState,
	reducers: {
		loadPositions(state, action) {
			state.loadedOptions.push(action.payload)
		},
		setToken(state, action) {
			state.token = action.payload
		},
		setSocketConnectionStatus(state, action) {
			state.socketConnection = action.payload
		},
		logIn(state, action) {
			state.isAuth = true
			state.firstName = action.payload.firstName
			state.lastName = action.payload.lastName
			state.token = action.payload.token
			state.email = action.payload.email
			state.userId = action.payload.id
			state.role = action.payload.roles[0]
			state.subUser = action.payload.subUser
			state.userName = action.payload.userName
		},

		logOut(state, action) {
			state.isAuth = false
			state.firstName = ""
			state.token = ""
			state.email = ""
			state.userId = ""
			state.company = ""
			state.role = ""
		},
		setCompany(state, action) {
			state.company = action.payload
		},
		setIsConnectedSocket(state, action) {
			state.isConectedSocket = action.payload
		},
		setPageLocation(state, action) {
			state.pageLocation = action.payload
		},
		setPrevPage(state, action) {
			state.prevPage = action.payload
		},
		setContext(state, action) {
			state.context = action.payload
		},
		setContextToUpdate(state, action) {
			state.contextToUpdate = action.payload
		},
		setPickerAction(state, action) {
			state.pickerAction = action.payload
		},
		setSinglePick(state, action) {
			state.singlePick = action.payload
		},
	},
})

export const {
	loadPositions,
	setPageLocation,
	setCompany,
	setSinglePick,
	logIn,
	setPickerAction,
	logOut,
	setPrevPage,
	setIsConnectedSocket,
	setContext,
	setContextToUpdate,
  setSocketConnectionStatus,
  setToken
} = globalConfig.actions
export default globalConfig.reducer
