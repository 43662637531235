
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    incomingObjects: new Array(),
}

const webProviderReducer = createSlice({
  name: 'webProvider',
  initialState,
  reducers: {
    setIncomingMatchingObject (state,action){
        if(!state.incomingObjects.find(c => action.payload.context == c.context)) {
            state.incomingObjects = [...state.incomingObjects, action.payload]
        }
		
	},
	removeIncomingMatchingObject (state,action) {
		state.incomingObjects = state.incomingObjects.filter(c => c.context != action.payload.context)
	},

    clear(state) {
      state.incomingObjects = []
    },

  },
})

export const { setIncomingMatchingObject,
    removeIncomingMatchingObject, clear } = webProviderReducer.actions
export default webProviderReducer.reducer