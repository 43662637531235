import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        registroDeCampo: null,
        regraDeCorrecao: null,
        tarefas : []
    }
}


const correcaoReducer = createSlice({
    name: 'correcaoReducer',
    initialState,
    reducers: {
        setTarefas(state, action) {
            state.form.tarefas = action.payload
        },
        populateToEditCorrecao(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
            state.form = initialState.form;
        },

    },
})

export const { setTarefas, populateToEditCorrecao, clear: clearOperacaoForm } = correcaoReducer.actions
export default correcaoReducer.reducer