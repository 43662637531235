import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		descricao: '',
		frequencia: '',
		recursos: [],
		manutencoes: [],
		modosDeFalha: [],
		classesDeRecurso: [],
		solicitanteId: '',
		tarefaPlanoDeManutencao: []
	},
	planosDeManutencao: [],
};

const planoDeManutencaoReducer = createSlice({
	name: 'planoDeManutencaoReducer',
	initialState,
	reducers: {
		setModosDeFalha(state, action) {
			state.form.modosDeFalha = action.payload;
		},
		setSolicitante(state, action) {
			state.form.solicitanteId = action.payload;
		},
		setDescricao(state, action) {
			state.form.descricao = action.payload;
		},
		populateToEdit(state, action) {
			state.form = action.payload;
		},
		setIsPopulatedPlano(state, action) {
			state.populated = action.payload;
		},
		setClassesDeRecurso(state, action) {
			state.form.classesDeRecurso = action.payload;
		},
		setFrequencia(state, action) {
			state.form.frequencia = action.payload;
		},
		addManutencao(state, action) {
			state.form.manutencoes = [...state.form.manutencoes, action.payload];
		},
		setManutencao(state, action) {
			state.form.manutencoes = action.payload;
		},
		setPlanosDeManutencao(state, action) {
			state.planosDeManutencao = action.payload;
		},
		setUpdateModoDeFalha(state, action) {
			let {index, modoDeFalha} = action.payload
			state.form.modosDeFalha[index] = modoDeFalha
		},
		setUpdatePlanoDeManutencaoSingleRow(state, action) {
			let index = state.planosDeManutencao.findIndex((p) => p.id == action.payload.id);
			state.planosDeManutencao[index] = action.payload;
		},
		setAddTarefasPlanoDeManutencao(state, action) {
			if (!state.form.tarefaPlanoDeManutencao) {
				state.form.tarefaPlanoDeManutencao = [action.payload];
			} else {
				state.form.tarefaPlanoDeManutencao = [...state.form.tarefaPlanoDeManutencao, action.payload];
			}
		},
		setTarefasPlanoDeMantuencao(state, action) {
			state.form.tarefaPlanoDeManutencao = action.payload;
		},
		setSequenciaTarefaPlanoDeManutencao(state, action) {
			let { index, valor } = action.payload;

			state.form.tarefaPlanoDeManutencao[index].sequencia = +valor;
		},
		setRecursos(state, action) {
			if (!action.payload) {
				state.recursos = new Array();
			} else {
				let { checked, recurso } = action.payload;

				if (!state.recursos) state.recursos = new Array();
				if (checked) {
					state.recursos = [...state.recursos, recurso];
				} else {
					state.recursos = state.recursos.filter((rec) => rec.id != recurso.id);
				}
			}
		},
		setRecursosToForm(state, action) {
			state.form.recursos = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
	},
});

export const {
	setRecursosToForm,
	setDescricao,
	populateToEdit,
	addManutencao,
	setManutencao,
	setRecursos,
	setIsPopulatedPlano,
	clear : clearPlanoDeManutencaoForm,
	setModosDeFalha,
	setClassesDeRecurso,
	setFrequencia,
	setSolicitante,
	setPlanosDeManutencao,
	setUpdatePlanoDeManutencaoSingleRow,
	setUpdateModoDeFalha,
	setAddTarefasPlanoDeManutencao,
	setSequenciaTarefaPlanoDeManutencao,
	setTarefasPlanoDeMantuencao
} = planoDeManutencaoReducer.actions;
export default planoDeManutencaoReducer.reducer;
