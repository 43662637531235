import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		estoqueId: null,
		tipo: '',
		estoqueDestinoId: null,
		flag: '',
	},
	movimentacoes: [],
	estoquesParaDestino: [],
}

const movimentacaoReducer = createSlice({
	name: 'movimentacaoReducer',
	initialState,
	reducers: {
		setMovimentacoes(state, action) {
			state.movimentacoes = action.payload
		},
		setEstoqueId(state, action) {
			state.form.estoqueId = action.payload
		},
		setFlagMovimentacao(state, action) {
			state.form.flag = action.payload
		},
		setEstoqueDestinoId(state, action) {
			state.form.estoqueDestinoId = action.payload
		},
		setEstoquesParaDestino(state, action) {
			state.estoquesParaDestino = action.payload
		},
		setTipoMovimentacao(state, action) {
			state.form.tipo = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setMovimentacoes,
	setEstoqueId,
	clear: clearMovimentacao,
	setTipoMovimentacao,
	setEstoqueDestinoId,
	setFlagMovimentacao,
	setEstoquesParaDestino,
} = movimentacaoReducer.actions
export default movimentacaoReducer.reducer
