import { createSlice } from '@reduxjs/toolkit'
import { InfinityDependencies } from 'mathjs'

const initialState = {
	form: {
		valor: '',
		dataDeLancamento: '',
		contadorId: '',
		contador: {},
	},
}

const registroReducer = createSlice({
	name: 'registroReducer',
	initialState,
	reducers: {
		setValorRegistro(state, action) {
			state.form.valor = action.payload
		},
		setDataRegistro(state, action) {
			state.form.dataDeLancamento = action.payload
		},
		setContador(state, action) {
			state.form.contadorId = action.payload.id
			state.form.contador = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const { setValorRegistro, setDataRegistro, setContador, clear : clearRegistroForm } = registroReducer.actions
export default registroReducer.reducer
