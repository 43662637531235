import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        
        descricao: "",
    }
}


const operacaoReducer = createSlice({
    name: 'operacaoReducer',
    initialState,
    reducers: {
        setDescricaoOperacao(state, action) {
            state.form.descricao = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
			state.form = initialState.form;
		},

    },
})

export const { setDescricaoOperacao, populateToEdit, clear : clearOperacaoForm } = operacaoReducer.actions
export default operacaoReducer.reducer