import { Container } from "react-bootstrap"
import { FcLock } from "react-icons/fc"
const AcessoNaoPermitido = () => {
	return (
		<Container>
			<FcLock style={{ marginLeft: "37.8%" }} size={300} />
			<div
				style={{
					width: "100%",
					maxWidth: "70%",
					margin: "0 auto",
					justifyContent: "center",
					textAlign: "center",
					display: "grid",
					gap: "3rem",
				}}
			>
				<h2>Não Permitido</h2>
				<h3>Você não tem permissão para executar esta operação</h3>
			</div>
		</Container>
	)
}

export default AcessoNaoPermitido
