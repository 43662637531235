import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        composicao: {
            itensDeComposicao: []
        }
    },
    materialComposto: {
        nome: "",
        composicao: null
    }
}


const materialReducer = createSlice({
    name: 'materialReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.form.nome = action.payload
        },
        setComposicaoMaterial(state, action) {
            state.form.composicao = action.payload
        },
        setNomeNovo(state, action) {
            state.materialComposto.nome = action.payload
        },
        setComposicaoMaterialNovo(state, action) {
            state.materialComposto.composicao = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
            state.materialComposto =action.payload 
        },
    
        clear(state, action) {
            state.form = initialState.form
            state.materialComposto = initialState.materialComposto
            
        },
        clearNovoMaterial(state, action) {
            state.materialComposto = initialState.materialComposto
        },

    },
})

export const { setNome, populateToEdit, clear: clearMaterialForm, setComposicaoMaterial,setNomeNovo,
    setComposicaoMaterialNovo,clearNovoMaterial } = materialReducer.actions
export default materialReducer.reducer