import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useWebProvider } from '../../hooks/useWebProvider';
import { endPointProcesso, paths } from '../../models/constantes';
import { useDispatch } from 'react-redux';
import { clearFluxoDeProcessoForm, populateToEdit } from '../../Reducers/processo-reducers/fluxoDeProcessoReducer';
import { useNavigate } from 'react-router-dom';
import FormField from '../../Components/FormField';

const FluxosDeProcesso = () => {
    const [selectedFluxo, setSelectedFluxo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fluxoDeProcesso, setFluxoDeProcesso] = useState([]);
    const [filteredFluxos, setFilteredFluxos] = useState([]);
    const [filter, setFilter] = useState('');
    const { controller } = useWebProvider();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFluxoDeProcesso = async () => {
            try {
                const response = await controller(null, endPointProcesso).readAll('fluxoDeProcesso');
                setFluxoDeProcesso(response);
                setFilteredFluxos(response); // Inicialmente, mostrar todos os fluxos
            } catch (error) {
                console.error('Erro ao carregar fluxo de processo:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFluxoDeProcesso();
    }, [controller]);

    const handleRowClick = (fluxo) => {
        setSelectedFluxo(fluxo);
        setShowModal(true);
    };

    const editFluxoHandler = () => {
        controller("fluxoDeProcesso").read(null, selectedFluxo.id).then(fluxo => {
            dispatch(populateToEdit(fluxo));
            navigate(paths.fluxoDeProcessoForm);
        })
    };

    const handleCloseModal = () => setShowModal(false);

    // Função para aplicar filtro
    const handleFilterChange = (e) => {
        const value = e
        setFilter(value);

        // Formata a data para um formato legível
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString();
        };

        // Filtra a lista de fluxos com base na descrição, processo, ou datas
        const filtered = fluxoDeProcesso.filter(fluxo => {
            const descricaoMatch = fluxo.descricao.toLowerCase().includes(value.toLowerCase());
            const processoMatch = fluxo.processo.nome.toLowerCase().includes(value.toLowerCase());

            const createdAtMatch = formatDate(fluxo.createdAt).includes(value);
            const updatedAtMatch = formatDate(fluxo.updatedAt).includes(value);

            return descricaoMatch || processoMatch || createdAtMatch || updatedAtMatch;
        });

        setFilteredFluxos(filtered);
    };

    const highlightText = (text, highlight) => {
        if (!highlight) return text;

        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? <strong key={index}>{part}</strong> : part
        );
    };

    if (loading) {
        return <p>Carregando fluxos de processo...</p>;
    }

    return (
        <>
            <Button onClick={() => {
                navigate(paths.fluxoDeProcessoForm);
                dispatch(clearFluxoDeProcessoForm());
            }}>Novo Fluxo</Button>

            {/* Campo de filtro */}
            <FormField label={"Procurar por fluxo"} val={filter} onValueUpdate={handleFilterChange} ty={"text"} />

            <Table striped bordered hover className="custom-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Descrição</th>
                        <th>Processo</th>
                        <th>Data de Criação</th>
                        <th>Última Atualização</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredFluxos.map((fluxo) => (
                        <tr
                            key={fluxo.id}
                            onClick={() => handleRowClick(fluxo)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td>{fluxo.id}</td>
                            <td>{highlightText(fluxo.descricao, filter)}</td>
                            <td>{highlightText(fluxo.processo.nome, filter)}</td>
                            <td>{new Date(fluxo.createdAt).toLocaleDateString()}</td>
                            <td>{new Date(fluxo.updatedAt).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showModal && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalhes do Fluxo de Processo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>ID: {selectedFluxo?.id}</p>
                        <p>Descrição: {selectedFluxo?.descricao}</p>
                        <p>Operações:</p>
                        <Button variant="primary" onClick={editFluxoHandler}>Editar Fluxo</Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default FluxosDeProcesso;