import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	form: {
		nome: '',
	},
};

const setorReducer = createSlice({
	name: 'setorReducer',
	initialState,
	reducers: {
		setNomeSetor(state, action) {
			state.form.nome = action.payload;
		},
		clear(state, action) {
			state.form = initialState.form;
		},
	},
});

export const { setNomeSetor, clear : clearSetorForm } = setorReducer.actions;
export default setorReducer.reducer;
