import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		id: '',
		estado: '',
		justificativa: '',
		extraFormId: '',
		mantenedorId: '',
	},
	cachedStatus: [],
}

const statusReducer = createSlice({
	name: 'statusReducer',
	initialState,
	reducers: {
		setJustificativa(state, action) {
			state.form.justificativa = action.payload
		},
		setEstado(state, action) {
			state.form.estado = action.payload
		},
		setExtraFormId(state, action) {
			state.form.extraFormId = action.payload
		},
		setCachedStatus(state, action) {
			state.cachedStatus = [...state.cachedStatus, action.payload]
		},
		updateCachedStatus(state, action) {
			let index = state.cachedStatus.findIndex((cS) => cS.id == action.payload.id)
			state.cachedStatus[index] = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		setMantenedor(state, action) {
			state.form.payloadStatus.data.mantenedorId = action.payload
		},
        clear(state, action) {
			state.form = initialState.form;
		},
	},
})

export const {
	populateToEdit,
	setCachedStatus,
	updateCachedStatus,
	setJustificativa,
	setEstado,
	setExtraFormId,
	setMantenedor,
    clear : clearStatusForm
} = statusReducer.actions
export default statusReducer.reducer
