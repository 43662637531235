import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        label: ""
    },
    cachedUnidades:[]

}


const unidadeReducer = createSlice({
    name: 'unidadeReducer',
    initialState,
    reducers: {
        setNomeUnidade(state, action) {
            state.form.nome = action.payload
        },
        setLabelUnidade(state, action) {
            state.form.label = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        setCachedUnidades(state, action){
            state.cachedUnidades = action.payload
        },
        clear(state, action) {
            state.form = initialState.form
        },

    },
})

export const { setNomeUnidade, setLabelUnidade, populateToEdit, clear : clearUnidadeForm, setCachedUnidades } = unidadeReducer.actions
export default unidadeReducer.reducer