import { useContext } from "react";
import { MatchingObject, WebProvider } from "../websocket/wsProvider";
import { basicController } from "../Http/basicController";



/**
 * 
 * @param {WebProvider} webProvider 
 * @param {function} sendMessage 
 * @param {function} subscribe 
 * @param {function} unsubscribe 
 * @param {basicController} controller 
 */
function UseWebProviderReturn(webProvider,sendMessage, subscribe, unsubscribe,controller, connectSocket ) {
	this.webProvider = webProvider
	this.sendMessage = sendMessage
	this.subscribe = subscribe
	this.unsubscribe = unsubscribe
	this.controller = controller
	this.connectSocket = connectSocket
}

/**
 * 
 * @param {string} matchingContext 
 * @param {funtion} refresherFunction 
 * @returns {UseWebProviderReturn}
 */
export const useWebProvider = () => {

	/**
	 * @type {WebProvider} 
	 */
	const webProvider = useContext(WebProvider)

	/**
	 * 
	 * @param {MatchingObject} matchObject 
	 */
	const subscribe = (matchObject) => {
		webProvider.subscribe(matchObject)
	}
	const unsubscribe = (matchObject) => {
		webProvider.unsubscribe(matchObject)
	}
	const sendMessage = (matchObject) => {
		webProvider.sendMessage(matchObject)
	}
	const connectSocket = (userName, company) => {
		webProvider.connectSocket(userName, company)
	}

	return {socket : webProvider.socket, sendMessage,subscribe, unsubscribe, controller: webProvider.basicController, connectSocket }
}



