import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        descricao: "",

    }, picker: {
        acao : null
    }
}


const acaoReducer = createSlice({
    name: 'acaoReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.form.nome = action.payload
        },
        setDescricao(state, action) {
            state.form.descricao = action.payload
        },
        setAcaoPicked(state, action) {
            state.picker.acao = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
            state.form = initialState.form
        },

    },
})

export const { setNome, setDescricao, setAcaoPicked,setRecurso, populateToEdit, setConsumos, clear : clearAcaoForm } = acaoReducer.actions
export default acaoReducer.reducer