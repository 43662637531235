
import FluxosDeProcesso from "../Screens/screens-processo/FluxosDeProcesso";
import { endPointCommons, endPointCorrecao, endPointManutencao, endPointPlanoDeControle, endPointProcesso, endPointSuprimentos, endPointUser, paths } from "./constantes";
import { lazy } from "react";

// Refatorando para Lazy Load
const AcaoPicker = lazy(() => import('../Components/AcaoPicker'));
const CampoDeVerificacao = lazy(() => import('../Components/CampoDeVerificacao'));
const ClasseDeRecursoPicker = lazy(() => import('../Components/ClasseDeRecursoPicker'));
const QrReader = lazy(() => import('../Components/QrReader'));
const RecursoPicker = lazy(() => import('../Components/RecursoPicker'));
const TarefaPicker = lazy(() => import('../Components/TarefaPicker'));
const TipoDeOrdemPicker = lazy(() => import('../Components/TipoDeOrdemModal'));
const UnidadeMaterial = lazy(() => import('../Components/UnidadeMaterialPicker'));
const AcessoNaoPermitido = lazy(() => import('../Screens/AcessoNaoPermitido'));
const ClasseDeRecursoForm = lazy(() => import('../Screens/ClasseDeRecursoForm'));
const ComposicaoForm = lazy(() => import('../Screens/ComposicaoForm'));
const FirstLogin = lazy(() => import('../Screens/FirstLogin'));
const FormularioForm = lazy(() => import('../Screens/FormularioForm'));
const Home = lazy(() => import('../Screens/Home'));
const Login = lazy(() => import('../Screens/Login'));
const AlarmePicker = lazy(() => import('../Screens/screens-commons/AlarmePicker'));
const ContadorForm = lazy(() => import('../Screens/screens-commons/ContadorForm'));
const FrequenciaForm = lazy(() => import('../Screens/screens-commons/FrequenciaForm'));
const RegistroForm = lazy(() => import('../Screens/screens-commons/RegistroForm'));
const SetorForm = lazy(() => import('../Screens/screens-commons/SetorForm'));
const TemplatesDeDados = lazy(() => import('../Screens/screens-commons/TemplatesDeDados'));
const TurnoForm = lazy(() => import('../Screens/screens-commons/TurnoForm'));
const UnidadeForm = lazy(() => import('../Screens/screens-commons/UnidadeForm'));
const TimeForm = lazy(() => import('../Screens/screens-gestao/TimeForm'));
const Times = lazy(() => import('../Screens/screens-gestao/Times'));
const AcaoForm = lazy(() => import('../Screens/screens-manutencao/AcaoForm'));
const AprovacaoStatus = lazy(() => import('../Screens/screens-manutencao/AprovacaoStatus'));
const ArvoreEstruturalForm = lazy(() => import("../Screens/screens-manutencao/ArvoreEstruturalForm"));
const BranchLevelForm = lazy(() => import("../Screens/screens-manutencao/BranchLevelForm"));
const ClasseDeComponenteForm = lazy(() => import('../Screens/screens-manutencao/ClasseDeComponenteForm'));
const ComponenteForm = lazy(() => import('../Screens/screens-manutencao/ComponenteForm'));
const CronogramaManutencao = lazy(() => import('../Screens/screens-manutencao/CronogramaManutencao'));
const DimensaoPicker = lazy(() => import('../Screens/screens-manutencao/DimensaoPicker'));
const Mantenedores = lazy(() => import('../Screens/screens-manutencao/Mantenedores'));
const MaterialComposto = lazy(() => import('../Screens/screens-manutencao/MaterialComposto'));
const MaterialSemComposicao = lazy(() => import('../Screens/screens-manutencao/MaterialSemComposicao'));
const ModoDeFalhaForm = lazy(() => import('../Screens/screens-manutencao/ModoDeFalhaForm'));
const OrdemDeManutencaoForm = lazy(() => import('../Screens/screens-manutencao/OrdemDeManutencaoForm'));
const OrdemDeServicoForm = lazy(() => import('../Screens/screens-manutencao/OrdemDeServicoForm'));
const OrdensDeManutencao = lazy(() => import('../Screens/screens-manutencao/OrdensDeManutencao'));
const OrdensDeServico = lazy(() => import('../Screens/screens-manutencao/OrdensDeServico'));
const PlanoDeManutencaoForm = lazy(() => import('../Screens/screens-manutencao/PlanoDeManutencaoForm'));
const PlanosDeManutencao = lazy(() => import('../Screens/screens-manutencao/PlanosDeManutencao'));
const RecursoForm = lazy(() => import("../Screens/screens-manutencao/RecursoForm"));
const RegistroDeTarefaForm = lazy(() => import('../Screens/screens-manutencao/RegistroDeTarefaForm'));
const SolicitacaoDeServicoForm = lazy(() => import('../Screens/screens-manutencao/SolicitacaoDeServicoForm'));
const SolicitacoesDeServico = lazy(() => import('../Screens/screens-manutencao/SolicitacoesDeServico'));
const TipoDeOrdemForm = lazy(() => import('../Screens/screens-manutencao/TipoDeOrdemForm'));
const CadernoDeVerificacao = lazy(() => import('../Screens/screens-processo/CadernoDeVerificacao'));
const CadernoDeVerificacaoForm = lazy(() => import('../Screens/screens-processo/CadernoDeVerificacaoForm'));
const Cadernos = lazy(() => import('../Screens/screens-processo/Cadernos'));
const ControleRow = lazy(() => import('../Screens/screens-processo/ControleRow'));
const EspecificacaoPicker = lazy(() => import('../Screens/screens-processo/EspecificacaoPicker'));
const FluxoDeProcessoForm = lazy(() => import("../Screens/screens-processo/FluxoDeProcessoForm"));
const FolhaDeVerificacaoForm = lazy(() => import('../Screens/screens-processo/FolhaDeVerificacaoForm'));
const LimiteDeControlePicker = lazy(() => import('../Screens/screens-processo/LimiteDeControlePicker'));
const LinhaDePlanoDeControleForm = lazy(() => import("../Screens/screens-processo/LinhaDePlanoDeControleForm"));
const OperacaoForm = lazy(() => import("../Screens/screens-processo/OperacaoForm"));
const ParametroForm = lazy(() => import("../Screens/screens-processo/ParametroForm"));
const PlanoDeControleForm = lazy(() => import('../Screens/screens-processo/PlanoDeControleForm'));
const ProcessoForm = lazy(() => import("../Screens/screens-processo/ProcessoForm"));
const RegistroDeCampoChart = lazy(() => import('../Screens/screens-processo/RegistroDeCampoChart'));
const RegraDeCorrecaoForm = lazy(() => import('../Screens/screens-processo/RegraDeCorrecaoForm'));
const EstoqueForm = lazy(() => import('../Screens/screens-suprimentos/EstoqueForm'));
const Estoques = lazy(() => import('../Screens/screens-suprimentos/Estoques'));
const Kardex = lazy(() => import('../Screens/screens-suprimentos/Kardex'));
const RequisicaoMaterialForm = lazy(() => import('../Screens/screens-suprimentos/RequisicaoMaterialForm'));
const RequisicoesMaterial = lazy(() => import('../Screens/screens-suprimentos/RequisicoesMaterial'));
const RecursoDisplayerTest = lazy(() => import('../Testing/RecursoDisplayerTest'));
const TestingAreaMenu = lazy(() => import('../Testing/TestingAreaMenu'));
const PlanosDeControle = lazy(() => import("../Screens/screens-processo/PlanosDeControle"));


export const routesConfig = [
    {
       service: "processo",
       routes: [
          {
             configuration: { context: "operacao", showCadastros: true, path: paths.operacaoForm, endPoint: endPointProcesso },
             element: (props) => <OperacaoForm  {...props} />,
          },
          {
             configuration: { context: "processo", showCadastros: true, path: paths.processoForm, endPoint: endPointProcesso },
             element: (props) => <ProcessoForm  {...props} />,
          },
          {
             configuration: { context: "fluxoDeProcesso", showCadastros: true, path: paths.fluxoDeProcessoForm, endPoint: endPointProcesso },
             element: (props) => <FluxoDeProcessoForm  {...props} />,
          },
          {
             configuration: { context: "planoDeControle", showCadastros: false, path: paths.planoDeControleForm, endPoint: endPointPlanoDeControle },
             element: (props) => <PlanoDeControleForm  {...props} />,
          },
          {
             configuration: { context: "linhaDePlanoDeControle", path: paths.linhaDePlanoDeControleForm, endPoint: endPointProcesso },
             element: (props) => <LinhaDePlanoDeControleForm {...props} />,
          },
          {
             configuration: { context: "parametro", showCadastros: true, path: paths.parametroForm, endPoint: endPointProcesso },
             element: (props) => <ParametroForm {...props} />,
          },
          {
            configuration: { context: "fluxoDeProcesso", showCadastros: true, path: paths.fluxosDeProcesso, endPoint: endPointProcesso },
            element: (props) => <FluxosDeProcesso {...props} />,
         },
          {
             configuration: { context: "limiteDeControle", showCadastros: true, path: paths.limiteDeControleForm, endPoint: endPointProcesso },
             element: (props) => <LimiteDeControlePicker {...props} />,
          },
          {
             configuration: { context: "especificacao", showCadastros: true, path: paths.especificacaoForm, endPoint: endPointProcesso },
             element: (props) => <EspecificacaoPicker  {...props} />,
          },
 
          {
             configuration: { context: "controle", showCadastros: true, path: paths.controleForm, endPoint: endPointProcesso },
             element: (props) => <ControleRow  {...props} />,
          },
          {
             configuration: { context: "cadernoDeVerificacao", showCadastros: true, path: paths.cadernoDeVerificacaoForm, endPoint: endPointProcesso },
             element: (props) => <CadernoDeVerificacaoForm  {...props} />,
          },
          {
             configuration: { context: "cadernoDeVerificacao", showCadastros: true, path: paths.cadernoDeVerificacao, endPoint: endPointProcesso },
             element: (props) => <CadernoDeVerificacao  {...props} />,
          },
          {
             configuration: { context: "folhaDeVerificacao", showCadastros: true, path: paths.folhaDeVerificacaoForm, endPoint: endPointProcesso },
             element: (props) => <FolhaDeVerificacaoForm  {...props} />,
          },
 
          {
             configuration: { context: "camopoDeVerificacao", showCadastros: true, path: paths.campoDeVerificacao, endPoint: endPointProcesso },
             element: (props) => <CampoDeVerificacao {...props} />
          },
          {
             configuration: { context: "registroDeCampo", showCadastros: true, path: paths.registroDeCampoChart, endPoint: endPointProcesso },
             element: (props) => <RegistroDeCampoChart {...props} />
          },
          {
             configuration: { context: "cadernos", path: paths.cadernos, endPoint: endPointProcesso },
             element: (props) => <Cadernos {...props} />
          },
          {
             configuration: { context: "formulario", showCadastros: true, path: paths.formularioForm, endPoint: endPointProcesso },
             element: (props) => <FormularioForm {...props} />
          },
          {
            configuration: { context: "planoDeControle", showCadastros: true, path: paths.planosDeControle, endPoint: endPointPlanoDeControle },
            element: (props) => <PlanosDeControle {...props} />
         },
          {
             configuration: { context: "regraDeCorrecao", showCadastros: true, path: paths.regraDeCorrecaoForm, endPoint: endPointCorrecao },
             element: (props) => <RegraDeCorrecaoForm {...props} />
          },
 
 
 
       ],
    },
    {
       service: "manutencao",
       routes: [
          {
             configuration: { context: "tipoDeOrdem", showCadastros: true, path: paths.tipoDeOrdemForm, endPoint: endPointManutencao },
             element: (props) => <TipoDeOrdemForm  {...props} />,
          },
          {
             configuration: { context: "arvoreEstrutural", showCadastros: false, path: paths.arvoreEstruturalForm, endPoint: endPointManutencao },
             element: (props) => <ArvoreEstruturalForm  {...props} />,
          },
          {
             configuration: { context: "recurso", showCadastros: true, path: paths.recursoForm, endPoint: endPointManutencao },
             element: (props) => <RecursoForm  {...props} />,
          },
          {
             configuration: { context: "planoDeManutencao", showCadastros: true, path: paths.planoDeManutencaoForm, endPoint: endPointManutencao },
             element: (props) => <PlanoDeManutencaoForm  {...props} />,
          },
          {
             configuration: { context: "branchLevel", showCadastros: true, path: paths.branchLevelForm, endPoint: endPointManutencao },
             element: (props) => <BranchLevelForm {...props} />,
          },
          {
             configuration: { context: "componente", showCadastros: true, path: paths.componenteForm, endPoint: endPointManutencao },
             element: (props) => <ComponenteForm  {...props} />,
          },
          {
             configuration: { context: "solicitacaoDeServico", showCadastros: false, path: paths.solicitacaoDeServicoForm, endPoint: endPointManutencao },
             element: (props) => <SolicitacaoDeServicoForm {...props} />,
          },
          {
             configuration: { context: "solicitacaoDeServico", showCadastros: false, path: paths.solicitacoesDeServico, endPoint: endPointManutencao },
             element: (props) => <SolicitacoesDeServico {...props} />,
          },
          {
             configuration: { context: "ordemDeServico", showCadastros: false, path: paths.ordemDeServicoForm, endPoint: endPointManutencao },
             element: (props) => <OrdemDeServicoForm {...props} />
          },
          {
             configuration: { context: "ordemDeManutencao", showCadastros: false, path: paths.ordemDeManutencaoForm, endPoint: endPointManutencao },
             element: (props) => <OrdemDeManutencaoForm {...props} />
          },
          {
             configuration: { context: "ordemDeManutencao", showCadastros: false, path: paths.ordensDeManutencao, endPoint: endPointManutencao },
             element: (props) => <OrdensDeManutencao {...props} />
          },
          {
             configuration: { context: "ordemDeServico", showCadastros: false, path: paths.ordensDeServico, endPoint: endPointManutencao },
             element: (props) => <OrdensDeServico {...props} />
          },
          {
             configuration: { context: "planoDeManutencao", showCadastros: true, path: paths.planosDeManutencao, endPoint: endPointManutencao },
             element: (props) => <PlanosDeManutencao {...props} />
          },
          {
             configuration: { context: "tipoDeOrdem", showCadastros: true, endPoint: endPointManutencao },
             element: (props) => <TipoDeOrdemPicker {...props} />
          },
 
          {
             configuration: { context: "recurso", showCadastros: false, path: paths.recursoPicker, endPoint: endPointManutencao },
             element: (props) => <RecursoPicker {...props} />,
          },
 
          {
             configuration: { context: "modoDeFalha", showCadastros: true, path: paths.modoDeFalhaForm, endPoint: endPointManutencao },
             element: (props) => <ModoDeFalhaForm {...props} />
          },
          {
             configuration: { context: "tarefa", showCadastros: false, path: paths.tarefaForm, endPoint: endPointManutencao },
             element: (props) => <TarefaPicker {...props} />
          },
          {
             configuration: { context: "acao", showCadastros: true, path: paths.acaoForm, endPoint: endPointManutencao },
             element: (props) => <AcaoForm {...props} />
          },
          {
             configuration: { context: "acao", showCadastros: false, path: paths.acaoPicker, endPoint: endPointManutencao },
             element: (props) => <AcaoPicker {...props} />
          },
          {
             configuration: { context: "classeDeRecurso", showCadastros: true, path: paths.classeDeRecursoForm, endPoint: endPointManutencao },
             element: (props) => <ClasseDeRecursoForm {...props} otherProps={props} />
          },
          {
             configuration: { context: "classeDeRecurso", showCadastros: true, path: paths.classeDeRecursoForm, endPoint: endPointManutencao },
             element: (props) => <ClasseDeRecursoPicker {...props} />
          },
          {
             configuration: { context: "dimensao", showCadastros: true, path: paths.dimensaoForm, endPoint: endPointManutencao },
             element: (props) => <DimensaoPicker {...props} />
          },
 
          {
             configuration: { context: "classeDeComponente", showCadastros: true, path: paths.classeDeComponenteForm, endPoint: endPointManutencao },
             element: (props) => <ClasseDeComponenteForm {...props} />
          },
          {
             configuration: { context: "registroDeTarefa", showCadastros: false, path: paths.registroDeTarefaForm, endPoint: endPointManutencao },
             element: (props) => <RegistroDeTarefaForm {...props} />
          },
          {
             configuration: { context: "mantenedor", showCadastros: true, path: paths.mantenedores, endPoint: endPointManutencao },
             element: (props) => <Mantenedores {...props} />
          },
 
          ////////////
          {
             configuration: { context: "testingArea", showCadastros: true, path: paths.testing, },
             element: (props) => <TestingAreaMenu {...props} />
          },
          ////////////
          {
             configuration: { context: "recursoDisplayer", showCadastros: true, path: paths.recursoDisplayer, },
             element: (props) => <RecursoDisplayerTest {...props} />
          },
          {
             configuration: { context: "cronogramaManutencao", showCadastros: false, path: paths.cronogramaManutencao, },
             element: (props) => <CronogramaManutencao {...props} />
          },
 
 
 
       ],
    },
    {
       service: "suprimentos",
       routes: [
          {
             configuration: { context: "material", showCadastros: true, path: paths.materialForm, endPoint: endPointSuprimentos },
             element: (props) => <MaterialSemComposicao context={"material"} {...props} />,
          },
          {
             configuration: { context: "material", showCadastros: true, path: paths.materialCompostoForm, endPoint: endPointSuprimentos },
             element: (props) => <MaterialComposto context={"material"} {...props} />,
          },
          {
             configuration: { context: "unidadeMaterial", showCadastros: true, path: paths.unidadeMaterialForm, endPoint: endPointSuprimentos },
             element: (props) => <UnidadeMaterial context={"unidadeMaterial"}  {...props} />,
          },
          {
             configuration: { context: "estoque", showCadastros: false, path: paths.estoqueForm, endPoint: endPointSuprimentos },
             element: (props) => <EstoqueForm context={"estoque"}  {...props} />,
          },
          {
             configuration: { context: "estoque", showCadastros: false, path: paths.estoques, endPoint: endPointSuprimentos },
             element: (props) => <Estoques context={"unidadeMaterial"}  {...props} />,
          },
          {
             configuration: { context: "estoque", showCadastros: false, path: paths.kardex, endPoint: endPointSuprimentos },
             element: (props) => <Kardex context={"estoque"}  {...props} />,
          },
          {
             configuration: { context: "requisicaoMaterial", showCadastros: false, path: paths.requisicoesDeMaterial, endPoint: endPointSuprimentos },
             element: (props) => <RequisicoesMaterial context={"requisicaoMaterial"}  {...props} />,
          },
          {
             configuration: { context: "requisicaoMaterial", showCadastros: false, path: paths.requisicaoMaterialForm, endPoint: endPointSuprimentos },
             element: (props) => <RequisicaoMaterialForm context={"requisicaoMaterial"}  {...props} />,
          },
          {
             configuration: { context: "composicao", showCadastros: false, path: paths.composicaoForm, endPoint: endPointSuprimentos },
             element: (props) => <ComposicaoForm {...props} />,
          },
 
 
 
       ],
    },
    {
       service: "commons",
       routes: [
          {
             configuration: { context: "contador", showCadastros: false, path: paths.contadorForm, endPoint: endPointCommons },
             element: (props) => <ContadorForm {...props} />
          },
          {
             configuration: { context: "templateDeDados", showCadastros: false, path: paths.templateDeDados, endPoint: endPointCommons },
             element: (props) => <TemplatesDeDados {...props} />
          },
          {
             configuration: { context: "unidade", showCadastros: true, path: paths.unidadeForm, endPoint: endPointCommons },
             element: (props) => <UnidadeForm context={"unidade"} showCadastros={true} {...props} />,
          },
          {
             configuration: { context: "alarme", showCadastros: true, path: paths.alarmeForm, endPoint: endPointCommons },
             element: (props) => <AlarmePicker  {...props} />,
          },
          {
             configuration: { context: "frequencia", showCadastros: true, path: paths.frequenciaForm, endPoint: endPointCommons },
             element: (props) => <FrequenciaForm  {...props} />,
          },
          {
             configuration: { context: "registro", showCadastros: true, path: paths.registroForm, endPoint: endPointCommons },
             element: (props) => <RegistroForm  {...props} />,
          },
          {
             configuration: { context: "turno", showCadastros: true, path: paths.turnoForm, endPoint: endPointCommons },
             element: (props) => <TurnoForm  {...props} />,
          },
          {
             configuration: { context: "setor", showCadastros: true, path: paths.setorForm, endPoint: endPointCommons },
             element: (props) => <SetorForm  {...props} />,
          },
          {
             configuration: { context: "status", showCadastros: true, path: paths.aprovaStatus, endPoint: endPointCommons },
             element: (props) => <AprovacaoStatus  {...props} />,
          },
       ],
    },
    {
       service: "user",
       routes: [
          {
             configuration: { context: "user", showCadastros: true, path: paths.home, endPoint: endPointUser },
             element: (props) => <Home {...props} />
          },
          {
             configuration: { context: "qrReader", showCadastros: false, path: paths.qrReader, endPoint: endPointUser },
             element: (props) => <QrReader {...props} />
          },
          {
             configuration: { context: "user", showCadastros: true, path: paths.loginForm, endPoint: endPointUser },
             element: (props) => <Login {...props} />
          },
          {
             configuration: { context: "user", showCadastros: true, path: paths.firstLoginForm, endPoint: endPointUser },
             element: (props) => <FirstLogin {...props} />
          },
          {
             configuration: {
                context: "user", showCadastros: false
                , path: paths.acessoNaoPermitido, endPoint: endPointUser
             },
             element: (props) => <AcessoNaoPermitido {...props} />
          },
          {
             configuration: { context: "team", showCadastros: false, path: paths.times, endPoint: endPointUser },
             element: (props) => <Times {...props} />
          },
          {
             configuration: { context: "team", showCadastros: false, path: paths.timeForm, endPoint: endPointUser },
             element: (props) => <TimeForm {...props} />
          },
       ]
 
    }
 ]