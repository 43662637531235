import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		descricao: "",
		planoDeManutencaoId: "",
		dataDeInicio: "",
		dataDeFim: "",
		ordensDeServico: [],
		frequencia: "",
		ordemDeManutencaoOSS: [],
		ordemDeManutencaoFrequencia: []
	},

	ordemDeManutencaoView: [],
}

const ordemDeManutencaoReducer = createSlice({
	name: "ordemDeManutencaoReducer",
	initialState,
	reducers: {
		setPlano(state, action) {
			state.form.planoDeManutencaoId = +action.payload
		},
		setOrdens(state, action) {
			state.form.ordensDeServico = action.payload
		},
		setOrdens(state, action) {
			state.form.ordemDeManutencaoOSS = action.payload
		},
		setWorkGroupOm(state, action) {
			state.form.workGroup = action.payload
		},
		addOrdensToOm(state, action) {
			if (state.form.ordemDeManutencaoOSS) {
				state.form.ordemDeManutencaoOSS = [
					...state.form.ordemDeManutencaoOSS,
					...action.payload,
				]
			} else {
				state.form.ordemDeManutencaoOSS = [action.payload]
			}
		},
		setUpdateOsInOm(state, action) {
			let index = state.form.ordemDeManutencaoOSS.findIndex(
				(omOs) => omOs.ordemDeServicoId == action.payload.id
			)
			state.form.ordemDeManutencaoOSS[index].ordemDeServico =
				action.payload
		},
		setOrdensView(state, action) {
			state.ordemDeManutencaoView = action.payload
		},
		setRecorrente(state, action) {
			state.form.isRecorrente = action.payload
		},
		setIsModelo(state, action) {
			state.form.isModelo = action.payload
		},
		setEscalaTempoPrevisto(state, action) {
			state.form.escala = action.payload
		},
		setTempoPrevisto(state, action) {
			state.form.tempoPrevisto = action.payload
		},
		setFrequencia(state, action) {
			state.form.ordemDeManutencaoFrequencia = [action.payload]
				
		},
		setUpdateFrequenciaOm(state, action) {
			state.form.ordemDeManutencaoFrequencia[0].frequencia = action.payload
				
		},
		setFrequenciasOm(state, action) {
			state.form.ordemDeManutencaoFrequencia = action.payload
				
		},
		setTeam(state, action) {
			state.form.ordemDeManutencaoTeam = action.payload
		},
		updateSingleOmRow(state, action) {
			let index = state.ordemDeManutencaoView.findIndex(
				(oM) => oM.id == action.payload.id
			)
			state.ordemDeManutencaoView[index] = action.payload
		},
		setDescricao(state, action) {
			state.form.descricao = action.payload
		},
		setDataDeInicio(state, action) {
			state.form.dataDeInicio = action.payload
		},
		setDataDeFim(state, action) {
			state.form.dataDeFim = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		populateToEditOrdemDeManutencao(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setPlano,
	setOrdens,
	setIsModelo,
	populateToEdit,
	setOrdensView,
	setDescricao,
	clear: clearOrdemDeManutencaoForm,
	setDataDeFim,
	setDataDeInicio,
	updateSingleOmRow,
	setWorkGroupOm,
	setFrequencia,
	setTeam,
	setRecorrente,
	populateToEditOrdemDeManutencao,
	addOrdensToOm,
	setTempoPrevisto,
	setEscalaTempoPrevisto,
	setUpdateOsInOm,
	setFrequenciasOm,
	setUpdateFrequenciaOm
} = ordemDeManutencaoReducer.actions
export default ordemDeManutencaoReducer.reducer
