import 'bootstrap/dist/css/bootstrap.min.css'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App'
import { persistor, store } from './store'
import './styles/index.css'
import './styles/tdHover.css'
import WebSocketProvider from './websocket/wsProvider'
import { ErrorBoundary } from 'react-error-boundary'
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
dayjs.extend(duration)
dayjs.extend(utc)
ReactDOM.render(
	<Provider store={store}>
		<PersistGate
			loading={null}
			persistor={persistor}>
			<ToastProvider>
				<WebSocketProvider>
					<StrictMode>
						<ErrorBoundary fallback={<p>Quebremo</p>}>
							<App />
						</ErrorBoundary>
					</StrictMode>
				</WebSocketProvider>
			</ToastProvider>
		</PersistGate>
	</Provider>,

	document.getElementById('root')
)

