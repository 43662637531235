import { createSlice } from '@reduxjs/toolkit';
import { stat } from 'fs';

const initialState = {
	form: {
		nome: '',
		teamMembers: [],
		setorId: 1
	},
	times: [],
};

const timeReducer = createSlice({
	name: 'timeReducer',
	initialState,
	reducers: {
		setNome(state, action) {
			state.form.nome = action.payload;
		},
		setSetor(state, action) {
			state.form.setorId = action.payload.id;
		},
		setTimes(state, action) {
			state.times = action.payload;
		},
		setTeamMember(state, action) {
			if (state.form.teamMembers.length == 0) state.form.teamMembers = [action.payload];
			else state.form.teamMembers = [...state.form.teamMembers, action.payload ];
		},
		setUpdateSingleTimeRow(state, action) {
			let index = state.times.findIndex(t => t.id == action.payload.id)
			state.times[index] = action.payload
		},
		setUpdateTeamMember(state, action) {
			let {index, teamMember} = action.payload
			state.form.teamMembers[index] = teamMember
		},
		setTeamMembers(state, action) {
			state.form.teamMembers = action.payload
		},
		populateToEditTime(state, action) {
			state.form = action.payload;
		},
		clear(state) {
			state.form = initialState.form;
		},
	},
});

export const {
	setNome,
	populateToEditTime,
	clear: clearTimeReducerForm,
	setTeamMember,
	setTimes,
	setTeamMembers, 
	setUpdateTeamMember,
	setSetor,
	setUpdateSingleTimeRow
} = timeReducer.actions;
export default timeReducer.reducer;
