import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        limitesDeControle : [],
//        parametro: ''
    }
}


const especificacaoReducer = createSlice({
    name: 'especificacaoReducer',
    initialState,
    reducers: {
        setLimitesDeControle(state, action) {
           state.form.limitesDeControle = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        populateToEditEspecificacao(state, action) {
            state.form = action.payload
        },
        updateLimiteDeControle(state, action) {
            let {index} = action.payload
            state.form.limitesDeControle[index] = action.payload
        },
        clear(state, action) {
			state.form = initialState.form;
		},

    }
})

export const { setLimitesDeControle,setParametroId, populateToEdit, clear : clearEspecificacaoForm, updateLimiteDeControle,populateToEditEspecificacao } = especificacaoReducer.actions
export default especificacaoReducer.reducer