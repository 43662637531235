import dayjs from "dayjs"
import { insert } from "underscore.string"
export const capitalize = (s) => {
	if (typeof s !== "string") return ""
return s.charAt(0).toUpperCase() + s.slice(1)
}

export const subId = (s) => {
	return s.replace("Id", "")
}
export const gentlyReadableFormula = (formula) => {
    const regex = /\[([^\]]+?)\]/g;
    
    return formula.replace(regex, (match, content) => {
      // Verificar se o conteúdo dentro dos colchetes é maior que 2 caracteres
      if (content.length > 2) {
        return `[${content.slice(0, 2)}]`;
      }
      // Retornar o conteúdo original se não for maior que 2 caracteres
      return match;
    });
  };
export const insertOn = (string, index, valueToInsert) => {
	const result = insert(string, index - 1, valueToInsert)
	return result
}
export const normalizeKey = (s) => {
	if (typeof s !== "string") return s
	let separado = s.replace(/(?=([A-Z]))/, " ")
	return capitalize(separado)
}

export const addApiKey = (url, key) => {
	return `${url}${key}`
}

export const formatIsoDate = (date) => {
	
	let day = dayjs(date).format('YYYY-MM-DDTHH:mm')
	
	
	return day
}

export const formulaFormater = (formula) => {
    let objFormulaMapping = {}
    const regex = /\[([Vv]\d+)\]/g
    const valuesToReplace = formula.matchAll(regex)
    valuesToReplace.forEach((v, i) => {
        if (!objFormulaMapping[v[1]]) objFormulaMapping[v[1]] = null
    })
    
    return objFormulaMapping
}

export const FormatDate = (data) => {
	const dataTokens = String(data).split("-")
	return dataTokens[2] + "-" + dataTokens[1] + "-" + dataTokens[0]
}

export const OnlyDate = (data, separator) => {
	let sep = separator ? separator : "-"
	const inverseDate = String(data).split("T")
	const onlyDate = inverseDate[0].split("-")
	return `${onlyDate[2] + sep + onlyDate[1] + sep + onlyDate[0]}`
}
export const toUpperCaseNoSpace = (texto) => {
	return texto.replace(/\s/g, "").toUpperCase()
}

export const DateAndTime = (data, separator) => {
	let sep = separator ? separator : "-"
	data = formatIsoDate(data)
	if (data) {
		const inverseDate = String(data).split("T")
		const onlyDate = inverseDate[0].split("-")
		let time = inverseDate[1].split("-")[0].split(":")
		time = `${time[0]}:${time[1]}`
		return `${onlyDate[2] + sep + onlyDate[1] + sep + onlyDate[0]} ${time}`
	} else {
		return ""
	}
}

export const calculateTimeBetweenTowIsoStringDate = (
	iniciadoEm,
	finalizadoEm,
	factor,
	scale
) => {
	const ini = new Date(iniciadoEm)
	const fini = new Date(finalizadoEm)
	let intervalo = fini.getTime() - ini.getTime()
	return `${(intervalo / factor).toFixed(2)} ${scale}`
}
export const onlyTime = (data) => {
	const inverseDate = String(data).split("T")
	const time = inverseDate[1].split(".")
	return time[0]
}
/**
 *
 * @param {*} path caminho do app SGP
 * @returns /ComponenteForm => Componente
 */
export const pathToHeader = (path) => {
	let espacar = path
		.replace(/([A-Z])/g, " $1")
		.trim()
		.replace(/\//g, "")
	let resultado = espacar.charAt(0).toUpperCase() + espacar.slice(1)

	let endsWithForm = resultado.endsWith("Form" || "form")

	if (endsWithForm) {
		return resultado.substring(0, resultado.length - 4)
	} else return resultado
}

/**@param {string} excelDate */
export const parseExcelDateToDate = (excelDate) => {
	let dateTimeTokens = Array.isArray(excelDate)
		? excelDate[0].split(" ")
		: excelDate.split(" ")
	let data = dateTimeTokens[0]
	let tempo = dateTimeTokens[1]
	let dataTokens = data.split("/")
	let tempoTokens = tempo.split(":")
	let dataTranformada = new Date(
		dataTokens[2],
		dataTokens[1] - 1,
		dataTokens[0],
		tempoTokens[0],
		tempoTokens[1],
		tempoTokens[2]
	)
	return dataTranformada
}

export const dUtc = (date) => {
	return DateAndTime()
}
export const especificacaoDisplay = (espec) => {
	let texto = ''
	espec.limitesDeControle.forEach((lc, index) => {
		texto = texto.concat(`${lc.nome} ${lc.valor}`)
		if (index != espec.limitesDeControle.length - 1) texto = texto.concat(" - ")

	})
	return texto
};
