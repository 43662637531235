import { createSlice } from '@reduxjs/toolkit'

export function PickerState(id, view,location) {
    this.id = id
    this.view = view
    this.location = location
    this.options = new Array()
}

const initialState = {
    pickerState: {},
}

const genericPickerReducer = createSlice({
    name: 'genericPickerReducer',
    initialState,
    reducers: {
        /**
         * 
         * @param {object} action 
         * @param {PickerState} action.payload
         */
        appendPickerState(state, action) {
            let { id } = action.payload
            state.pickerState[id] = action.payload
        },
        removePickerState(state, action) {
           delete state.pickerState[action.payload]
        },
        closePicker(state, action) {
            state.pickerState[action.payload].view = true
        },

        openPicker(state, action) {
            state.pickerState[action.payload].view = false
        },
        clearPickerState(state) {
            state.pickerState = {}
        },
    },
})

export const { appendPickerState, removePickerState, closePicker, openPicker ,clearPickerState} = genericPickerReducer.actions
export default genericPickerReducer.reducer
