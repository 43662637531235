import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		dataDoConsumo: '',
		setor: '',
	},
}

const consumoReducer = createSlice({
	name: 'consumoReducer',
	initialState,
	reducers: {
		populateToEdit(state, action) {
			state.form = action.payload
		},
		setSetorConsumo(state, action) {
			state.form.setor = action.payload
		},
		clear(state) {
			state.form = {}
		},
	},
})

export const { populateToEdit, clear : clearConsumoForm,  } = consumoReducer.actions
export default consumoReducer.reducer
