import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    trocaComponenteForm: {
        razaoDeTroca: "",
    }
}


const trocaComponenteReducer = createSlice({
    name: 'trocaComponenteReducer',
    initialState,
    reducers: {
        setRazaoDeTroca(state, action) {
            state.trocaComponenteForm.razaoDeTroca = action.payload
        },
        populateToEdit(state, action) {
            state.trocaComponenteForm = action.payload
        },
        clearForm(state) {
            state.trocaComponenteForm = {}
        },

    },
})

export const { setRazaoDeTroca, populateToEdit,setConsumos, clearForm } = trocaComponenteReducer.actions
export default trocaComponenteReducer.reducer