import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		nome: '',
		level: 0,
		color: '',
		hasComponents: false,
		excludeLevels: 0,
	},
	levels: [],
}

const branchLevelReducer = createSlice({
	name: 'branchLevelReducer',
	initialState,
	reducers: {
		setNome(state, action) {
			state.form.nome = action.payload
		},
		setLevel(state, action) {
			state.form.level = action.payload
		},
		setColor(state, action) {
			state.form.color = action.payload
		},
		setHaveComponente(state, action) {
			state.form.hasComponents = action.payload
		},
		setExcludeLevels(state, action) {
			state.form.excludeLevels = action.payload
		},
		setLevels(state, action) {
			state.levels = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setNome,
	setLevel,
	populateToEdit,
	setColor,
	setHaveComponente,
	clear : clearBranchLevelForm,
	setExcludeLevels,
	setLevels,
} = branchLevelReducer.actions
export default branchLevelReducer.reducer
