import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	form: {
		
		tipo: "",
		stopType: false,
		plannedType: false,
		tipoDeOrdemTag: {
			tag: {
				descricao: "",
			},
		},
	},
	picker: {
		tipo: "",
		stopType: false,
		plannedType: false,
	},
}

const tipoDeOrdemReducer = createSlice({
	name: "tipoDeOrdemReducer",
	initialState,
	reducers: {
		setTipo(state, action) {
			state.form.tipo = action.payload
		},
		setTipoPicker(state, action) {
			state.picker = action.payload
		},
		setIsStopType(state, action) {
			state.form.stopType = action.payload
		},
		setDescricaoTipoDeOrdemTag(state, action) {
			state.form.tipoDeOrdemTag.tag.descricao = action.payload
		},
		setIsStopTypePicker(state, action) {
			state.picker.stopType = action.payload
		},
		setIsPlannedType(state, action) {
			state.form.plannedType = action.payload
		},
		setIsPlannedTypePicker(state, action) {
			state.picker.plannedType = action.payload
		},
		populateToEditTipoPicker(state, action) {
			state.picker.tipo = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const {
	setTipo,
	populateToEdit,
	setTipoPicker,
	populateToEditTipoPicker,
	clear: clearTipoDeOrdemForm,
	setIsStopType,
	setIsStopTypePicker,
	setIsPlannedType,
	setIsPlannedTypePicker,
	 setDescricaoTipoDeOrdemTag,
} = tipoDeOrdemReducer.actions
export default tipoDeOrdemReducer.reducer
