import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    form: {
        material : "",
        formula : "",
        materialId : ""
    },
};

const calculoDeCorrecaoReducer = createSlice({
    name: 'calculoDeCorrecaoReducer',
    initialState,
    reducers: {
        setMaterial(state, action) {
            state.form.material = action.payload;
            state.form.materialId = action.payload.id;
        },
        setUnidade(state, action) {
            state.form.unidade = action.payload.label
        },
        setFormula(state, action) {
            state.form.formula = action.payload || "[v0]"
        },
        populateToEdit(state, action) {
			state.form = action.payload
		},
        clearCalculoDeCorrecao(state, action) {
            state.form = initialState.form;
        },
    },
});

export const {
    setMaterial, setFormula,setUnidade ,clearCalculoDeCorrecao, populateToEdit
} = calculoDeCorrecaoReducer.actions;
export default calculoDeCorrecaoReducer.reducer;
