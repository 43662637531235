import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        descricao: "",
    },picker :{
        classe : null
    }
}

const classeDeComponenteReducer = createSlice({
    name: 'classeDeComponenteReducer',
    initialState,
    reducers: {
        setClasseDeComponenteDescricao(state, action) {
            state.form.descricao = action.payload
        },
        setClasseDeComponentePicker(state, action) {
            state.picker.classe = action.payload
        },
        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
            state.form = initialState.form
        },

    },
})

export const { setClasseDeComponenteDescricao ,populateToEdit,setClasseDeComponentePicker, clear : clearClasseDeComponenteForm } = classeDeComponenteReducer.actions
export default classeDeComponenteReducer.reducer