import { Client } from "@stomp/stompjs"
import axios from "axios"
import React, { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { Socket } from "socket.io"
import { io } from "socket.io-client"
import { setSocketConnectionStatus } from "../Reducers/globalConfigReducer"
import { logOut } from "../Reducers/globalConfigReducer"
import { endPointRabbit } from "../models/constantes"
import { routesConfig } from "../models/routesConfig"

import { store } from "../store"

/**
 * @callback actionFunction
 * @param {object} action redux action pattern
 * @param {string} action.type redux action pattern
 * @param {object} action.payload redux action pattern
 * @param {fuction} dispatch dispatch ser utilizado caso o componente nao tennha acesso a ao useDispatch
 *
 */

/**
 *
 * @param {string} context contexto utilizado para identificar se o componente precisa ser atualizado
 * @param {actionFunction} refresher funcao de callback chamada para executar a logica no escopo do componente que esta chamando a shiftMessages
 */
export function MatchingObject(context, location = "*", refresher) {
	this.context = context
	//vem do back end
	this.payload = null
	this.location = location
	this.refresher = refresher
}

const WebProvider = createContext(null)

export { WebProvider }

export default ({ children }) => {
	const dispatch = useDispatch()
	const [matchingObjects, setMatchingObjects] = useState([])
	const toast = useToasts()

	/**
	 * @type {[Socket, React.Dispatch<Socket>]} state
	 */
	const [socket, setSocket] = useState(null)

	/* useEffect(
		() => {
			
		},
		[
			userName, company
		]
	) */
	const connectSocket = (userName, company) => {
		if (socket) {
			dispatch(setSocketConnectionStatus(true))
			setSocket(socket)
		}
		if (!socket && userName && company) {
			let socket = socketClient(
				endPointRabbit,
				userName,
				company,
				onMessageReceive
			)
			setSocket(socket)
		}
	}

	const setRestApi = (context, baseEndPoint) => {
		let endPointToConfig = null
		if (baseEndPoint) {
			endPointToConfig = baseEndPoint
		} else {
			routesConfig.forEach((rC) =>
				rC.routes.forEach((route) => {
					if (route.configuration.context == context) {
						endPointToConfig = route.configuration.endPoint
					}
				})
			)
		}

		const http = axios.create({
			baseURL: endPointToConfig,
		})

		http.interceptors.response.use(
			(res) => {
				/* if (res.status == 200 && res) {
					if (res.data) {
						if (res.data.content) {
							return res.data.content
						}
						if (res.data.content == null) {
							return res.data.content
						} else {
							return res.data
						}
					} */
				return res.data.content ? res.data.content : res.data
				/* 	} */
			},
			(err) => {
				if (err.response?.status == 401) {
					dispatch(logOut())
				}
				if (err.response?.status == 406) {
					err.response.data.errors.forEach((err) =>
						toast.addToast(err)
					)
				}
				if (err.response?.status == 403) {
					toast.addToast(
						"Voce nao tem permissao para acessar parte/total do conteudo dessa pagina",
						{ autoDismiss: true }
					)
				}

				toast.addToast("Erro ao carregar conteudo da pagina", {
					autoDismiss: true,
				})
			}
		)
		http.interceptors.request.use(async (config) => {
			const token = store.getState().global.token
			if (token) {
				config.headers.Authorization = `${token}`
			}

			return config
		})

		return http
	}

	const basicController = (context, baseEndPoint) => {
		let api = setRestApi(context, baseEndPoint)
		return {
			get: (path) => {
				let pathh = path ? path : context
				return api.get(`${pathh}`, { headers: { Contexto: context } })
			},
			post: (path, data, extraHeaders) => {
				let pathh = path ? path : context
				return api.post(`${pathh}`, data, {
					headers: { Contexto: context, ...extraHeaders },
				})
			},
			put: (path, data) => {
				let pathh = path ? path : context
				return api.put(`${pathh}`, data, {
					headers: { Contexto: context },
				})
			},
			delete: (path) => {
				let pathh = path ? path : context
				return api.delete(`${pathh}`, {
					headers: { Contexto: context },
				})
			},
			save: (path, data, extraHeaders) => {
				let pathh = path ? path : context
				if (data.id) {
					return api.put(`${pathh}/${data.id}`, data, {
						headers: { Contexto: context, ...extraHeaders },
					})
				} else {
					return api.post(pathh, data, {
						headers: { Contexto: context, ...extraHeaders },
					})
				}
			},
			read: (path, id) => {
				let pathh = path ? path : context
				return api.get(`${pathh}/${id}`, {
					headers: { Contexto: context },
				})
			},
			readAll: (path) => {
				let pathh = path ? path : context
				return api.get(`${pathh}/all`, {
					headers: { Contexto: context },
				})
			},
			readAllwithPage: (path, page, size) => {
				let pathh = path ? path : context
				return api.get(`${pathh}?page=${page}&size=${size}`, {
					headers: { Contexto: context },
				})
			},
			delete: (path, id) => {
				let pathh = path ? path : context
				return api.delete(`${pathh}/${id}`, {
					headers: { Contexto: context },
				})
			},
		}
	}

	const onMessageReceive = (incomingMatchingObject) => {
		console.log(incomingMatchingObject)
		setMatchingObjects((matchingObjects) => {
			matchingObjects &&
				matchingObjects.forEach((mO) => {
					if (
						mO.context == incomingMatchingObject.context &&
						incomingMatchingObject.location == mO.location
					) {
						mO.refresher(incomingMatchingObject.payload, dispatch)
					}
				})
			return matchingObjects
		})
	}
	const socketClient = (endpoint, user, company, onMessage) => {
		let socket = io(endpoint, {
			extraHeaders: {
				user: user,
				company: company,
			},
		})
		socket.on("connect", () => {
			dispatch(setSocketConnectionStatus(true))
			console.log("Socket connectado: ", socket.id) // x8WIv7-mJelg7on_ALbx
		})
		socket.on("message", (message) => {
			onMessage(message)
		})

		socket.on("connect_error", (err) => {
			dispatch(setSocketConnectionStatus(false))
			setTimeout(() => {
				socket.connect()
			}, 1000)
		})

		socket.on("disconnect", (msg) => {
			dispatch(setSocketConnectionStatus(false))
			console.log("Socket desconectado: ", msg) // x8WIv7-mJelg7on_ALbx
		})
		socket.on("close", (msg) => {
			dispatch(setSocketConnectionStatus(false))
			console.log("Socket fechado: ", msg) // x8WIv7-mJelg7on_ALbx
		})

		socket.on("error", (msg) => {
			dispatch(setSocketConnectionStatus(false))
			console.log("sockert error: ", msg) // x8WIv7-mJelg7on_ALbx
		})
		return socket
	}

	/**
	 *
	 * @param {*} endpoint
	 * @param {*} user
	 * @param {*} company
	 * @param {*} onMessage
	 * @returns {Socket}
	 */
	const stompClient = (endpoint, user, company, onMessage) => {
		let socket = null

		const onConnect = () => {
			socket.subscribe("/", (message) => {
				console.log(message)
			})
		}

		const onDisconnect = () => {
			socket.deactivate()
		}

		socket = new Client({
			brokerURL: "http://localhost:3005",
			reconnectDelay: 5000,
			heartbeatIncoming: 4000,
			heartbeatOutgoing: 4000,
			onConnect: onConnect,
			onDisconnect: onDisconnect,
		})

		socket.activate()
		return socket
	}

	const sendMessage = (matchingObject) => {
		if (socket) socket.send(JSON.stringify(matchingObject))
	}

	const subscribe = (matchingObject) => {
		setMatchingObjects((matchingObjects) => {
			if (
				!matchingObjects.find(
					(mO) => mO.context == matchingObject.context
				)
			)
				return [...matchingObjects, matchingObject]
			else return matchingObjects
		})
	}
	const unsubscribe = (matchingObject) => {
		setMatchingObjects((consumers) =>
			consumers.filter((c) => c.context != matchingObject.context)
		)
	}

	const value = {
		socket,
		sendMessage,
		unsubscribe,
		subscribe,
		setRestApi,
		basicController,
		stompClient,
		connectSocket,
	}

	return <WebProvider.Provider value={value}>{children}</WebProvider.Provider>
}
