import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	form: {
		// especificacao: null,
		// acionadores : null,
		nome: '',
		unidadeId: '',

		// formula: "[V]",
		// habilitado: false,
		// titula: false,
		// showChart: true,
		// processoId: "",
		// etapaId: "",
	},
}

const parametroReducer = createSlice({
	name: 'parametroReducer',
	initialState,
	reducers: {
		// setEspecificacao(state, action) {
		//     state.form.especificacao = action.payload
		// },
		// setAcionador(state, action) {
		//     if (state.form.acionadores) {
		//         state.form.acionadores = [...state.form.acionadores, action.payload]
		//     } else {
		//         state.form.acionadores = new Array()
		//         state.form.acionadores.push(action.payload)
		//     }

		//},
		setNome(state, action) {
			state.form.nome = action.payload
		},
		// setFormula(state, action) {
		//     state.form.formula = action.payload
		// },
		// setHabilitado(state, action) {
		//     state.form.habilitado = action.payload
		// },
		// setTitula(state, action) {
		//     state.form.titula = action.payload
		// },
		// setShowChart(state, action) {
		//     state.form.showChart = action.payload
		// },
		// setProcessoId(state, action) {
		//     state.form.processoId = action.payload
		// },
		// setEtapaId(state, action) {
		//     state.form.etapaId = action.payload
		// },
		setUnidade(state, action) {
			state.form.unidadeId = action.payload
		},

		// clearParametroToAnalise(state, action) {
		//     state.parametrosView = []
		// },
		populateToEditparametro(state, action) {
			state.form = action.payload
		},
		populateToEdit(state, action) {
			state.form = action.payload
		},
		clear(state, action) {
			state.form = initialState.form
		},
	},
})

export const { setNome, setUnidade, populateToEditparametro, clear : clearParametroForm, populateToEdit } =
	parametroReducer.actions
export default parametroReducer.reducer
