
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './Root';
import './styles/hiddenHoverButton.css';



class App extends React.Component {



  render() {
    const router = createBrowserRouter([
      { path: "*", Component: Root },
    ]);
    return <RouterProvider  router={router} />
  }

}

export default App
