import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	nodeRef: null,
	branchsDisplay: [],
	refreshNode: null,
	branchMapDisplay: null,
	levels: null,
	draggedBranchNode: null
};

const arvoreEstruturalReducer = createSlice({
	name: 'arvoreEstruturalReducer',
	initialState,
	reducers: {
		setDraggedBranchNode(state, action) {
			if (action.payload.id != state.draggedBranchNode?.id) state.draggedBranchNode = action.payload
		},
		draggBranchNode(state,action) {
			let pos = action.payload
			if(state.branchMapDisplay[pos][0] != action.payload) {
				state.branchMapDisplay[pos][1].branchNodes = [...state.branchMapDisplay[pos][1].branchNodes, state.draggedBranchNode]
			}
		},
		setBranchs(state, action) {
			let branchs = action.payload;
			state.branchsDisplay = branchs;
		},
		setActualBranchMapDisplay(state, action) {
			state.branchMapDisplay = action.payload;
		},
		setLevels(state, action) {
			state.levels = action.payload;
		},
		updateBranch(state, action) {
			let index = state.branchMapDisplay.findIndex(b => b.id == action.payload.id)
			if(!(index < 0))state.branchMapDisplay[index] = action.payload;
			else state.branchMapDisplay = [action.payload];
			
		},
		updateBNodeRecurso(state, action) {
			let rIndex 
			let bIndex
			 state.branchMapDisplay.forEach((b, i) => b.branchNodes.forEach((bN, j) =>{
				if(bN.recurso.id == action.payload.id){
					rIndex = i
					bIndex = j
				}
			 } ))
		state.branchMapDisplay[rIndex].branchNodes[bIndex].recurso = action.payload;
			
			
		},
		updateBranchLevel(state, action) {
			let index = state.branchMapDisplay.findIndex(b => b.branchLevel.level == action.payload.level)
			if(!(index < 0))state.branchMapDisplay[index].branchLevel = action.payload
			else state.branchMapDisplay = action.payload
			
		},
		setSingleLevel(state, action) {
			let index = state.levels.findIndex(l => l.id == action.payload.id)
			state.levels[index] = action.payload
		},
		setNodeRef(state, action) {
			state.nodeRef = action.payload;
		},
		setRefreshNode(state, action) {
			state.refreshNode = action.payload;
		},
		setClickedNode(state, action) {
			state.nodeClicked.push(action.payload);
		},
		setIsPicker(state, action) {
			state.isPicker = action.payload;
		},
		updateBranchMapDisplay(state, action) {
			state.isPicker = action.payload;
		},
		clear(state, action) {
			state.isPicker = false
		},
	},
});

export const {
	setBranchs,
	setFiltroNodeDisplay,
	setIsPicker,
	clear : clearArvoreEstruturalForm,
	setLevels,
	setActualBranchMapDisplay,
	setClickedNode,
	setRefreshNode,
	setNodeRef,
	moveUpRecurso,
	setDraggedBranchNode,
	updateBranch,
	updateBranchLevel,
	updateBNodeRecurso

} = arvoreEstruturalReducer.actions;
export default arvoreEstruturalReducer.reducer;
