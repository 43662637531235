import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    form: {
        nome: "",
        descricao: "",
        nivelHierarquia: ""

    }
}

const classeDeRecursoReducer = createSlice({
    name: 'classeDeRecursoReducer',
    initialState,
    reducers: {
        setNome(state, action) {
            state.form.nome = action.payload
        },
        setDescricao(state, action) {
            state.form.descricao = action.payload
        },

        populateToEdit(state, action) {
            state.form = action.payload
        },
        clear(state, action) {
			state.form = initialState.form;
		},

    },
})

export const { setNome,setDescricao, populateToEdit, clear : clearClasseDeRecursoForm } = classeDeRecursoReducer.actions
export default classeDeRecursoReducer.reducer